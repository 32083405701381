const initialState = {
    categories: [],
    products: [],
    marketing: [],
    reviews: [],
    advertises: [],
    tasks: []
  };
  
  export const userReducer = (state = initialState, action) => {
    console.log(action);
    switch (action.type) {
      case "LOAD_ALL_CATEGORIES":
        state.categories = action.payload;
        break;
      case "LOAD_ALL_PRODUCTS":
        state.products = action.payload;
        break;
      case "SEARCH_PRODUCTS":
        state.products = action.payload;
        break;
      case "SEARCH_PRODUCTS_OF_CATEGORY":
        state.products = action.payload;
        break;
      case "SEND_MESSAGE":
        state.marketing = action.payload;
        break;
      case "LOAD_ALL_REVIEWS":
        state.reviews = action.payload;
        break;
      case "ADD_REVIEW":
        state.reviews.push(action.payload);
        break;
      case "LOAD_ALL_ADVERTISES":
        state.advertises = action.payload;
        break;
      case "REMOVE_REVIEW":
        state.reviews = state.reviews.filter(
          (item) => item.id !== action.payload
        );
        break;

    case "FETCH_TASKS":
      state.tasks = action.payload;
      break;
      
    case "CLEAR_TASKS":
      state.tasks = [];
      break;

    case "ADD_TASK":
      state.tasks.push(action.payload);
      break;
    
    case "UPDATE_TASKS":
      state.tasks = action.payload;
      break;
      default:
        console.log("State", state);
    }
    return state;
  };
  