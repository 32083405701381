import React, { useEffect, useState } from 'react';
import { Button, Flex, Popconfirm, Table } from 'antd';
import { ImportOutlined, UserAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom"
import * as actions from "store/actions/AuthActions"
import * as userActions from "store/actions/UserActions";
import useScrollBottom from 'CustomHooks/useScrollBottom';
import { Input, Spin } from "antd";
import axios from 'axios';
import { useDispatch } from 'react-redux';

const ContactsTable = () => {

    const { Search } = Input;

    const [searchTerm, setSearchTerm] = useState("");

    const [totalRows, setTotalRows] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState(false);

    const [dataSource, setDataSource] = useState([]);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const fetchContacts = async (page) => {
        setLoading(true);
        try {

            let response = await actions.loadContacts(page, searchTerm);
            console.log({ response, dataSource });
            if (response.status == 200) {
                const data = response.data;
                setDataSource([...dataSource, ...data.results])
                setTotalRows(data.count);
            }
        } catch (e) {

        }
        setLoading(false);
    }

    const onSearch = (value) => {
        if (searchTerm === value) {

        } else {
            setSearchTerm(value);
            setDataSource([]);
            setPageNumber(0);
        }
    }

    useEffect(() => {
        let p = pageNumber + 1;
        // console.log({ next_p: p, dataSource, totalRows });
        if (p === 1) {
            fetchContacts(p);
            setPageNumber(p);
        } else if (dataSource && dataSource.length < totalRows) {
            fetchContacts(p);
            setPageNumber(p);
        }
    }, [searchTerm]);

    const handleDelete = async (id) => {
        const newData = dataSource.find((item) => item.id === id);
        // setDataSource(newData);
        const response = await actions.deleteContacts(
            newData.id
        );
        console.log(response);
        if (response) {
            fetchContacts();
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
    }


    const defaultColumns = [
        {
            title: 'FIRST NAME',
            dataIndex: 'first_name',
            key: 'first_name',
            sorter: (a, b) => a?.first_name.localeCompare(b?.first_name),
        },
        {
            title: 'LAST NAME',
            dataIndex: 'last_name',
            key: 'last_name',
            sorter: (a, b) => a?.last_name.localeCompare(b?.last_name),
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a?.email.localeCompare(b?.email),
        },
        {
            title: 'Phone',
            dataIndex: 'phone_number',
            key: 'phone_number',
            sorter: (a, b) => a?.phone_number.localeCompare(b?.phone_number),
        },
        {
            title: 'Client',
            dataIndex: 'client_name',
            key: 'client_name',
            sorter: (a, b) => a?.client_name.localeCompare(b?.client_name),
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor_name',
            key: 'vendor_name',
            sorter: (a, b) => a?.vendor_name.localeCompare(b?.vendor_name),
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            sorter: (a, b) => a?.company.localeCompare(b?.company),
        },
        {
            title: 'Job Title',
            dataIndex: 'job_title',
            key: 'job_title',
            sorter: (a, b) => a?.job_title.localeCompare(b?.job_title),
        },
        {
            title: 'EDIT',
            dataIndex: 'edit',
            key: 'edit',
            render: (_, record) => {
                // console.log(record);
                return dataSource?.length >= 1 ? (
                    <Popconfirm title="Sure to edit?"
                        onClick={() => navigate(`/leads/edit/${record.id}/`)}
                    //  onConfirm={() => handleDelete(record.key)}
                    >
                        <a>
                            <EditOutlined
                                style={{
                                    verticalAlign: 'middle',
                                }}
                            />
                        </a>
                    </Popconfirm>
                ) : null
            }

        },
        {
            title: 'DELETE',
            dataIndex: 'delete',
            key: 'delete',
            render: (_, record) => {
                // console.log(record)
                return dataSource?.length >= 1 ? (
                    <Popconfirm title="Sure to delete?"
                        onConfirm={() => handleDelete(record.id)}
                    >
                        <a>
                            <DeleteOutlined />
                        </a>
                    </Popconfirm>
                ) : null
            }
        },
    ];

    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };


    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    const [selectionType, setSelectionType] = useState('checkbox');

    // const handleScroll = (e) => {
    //     const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    //     if (bottom) {
    //         fetchData();
    //     }
    // };

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            let p = pageNumber + 1;
            // console.log({ next_p: p, dataSource, totalRows });
            if (p === 1) {
                fetchContacts(p);
                setPageNumber(p);
            } else if (dataSource && dataSource.length < totalRows) {
                fetchContacts(p);
                setPageNumber(p);
            }
        }
    };

    return (
        <div style={{ padding: 10 }}>
            <Flex gap='large' justify='space-between'>
                <Flex gap='large' align='center'>
                    <div>
                        ({dataSource?.length} / {totalRows})
                    </div>

                    <div>
                        <Search placeholder="input search text" onSearch={onSearch} style={{ width: 300 }} />
                    </div>
                </Flex>
                <Flex gap='large'>
                    <Button
                        // onClick={handleAdd}
                        onClick={() => {
                            dispatch(userActions.createExportTask({ model: "Contact" }))
                            // const response = await axios.post('/contact/contacts/export/');
                            // if(response.status === 200) {
                            //     console.log(response.data)
                            // }
                            // dispatch({type: "ADD_TASK", payload: {id: null, process: "Contact", status: "started" }});
                        }}
                        icon={<UserAddOutlined />}
                        type="primary"
                    >
                        Export Contacts
                    </Button>
                    <Button
                        // onClick={handleAdd}
                        onClick={() => navigate("/contacts/new")}
                        icon={<UserAddOutlined />}
                        type="primary"
                    >
                        Add Contact
                    </Button>
                </Flex>
            </Flex>
            <Flex>
                <div style={{ height: "75vh", overflow: "hidden", marginTop: 20 }} onScroll={handleScroll}>
                    <Table
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection,
                        }}
                        bordered
                        size="small"
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={row => row.id}
                        pagination={false}
                        scrollToFirstRowOnChange
                        stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                        scroll={{ y: `calc(75vh - 150px)` }}
                    />
                    <div style={{ margin: 20 }}>{
                        loading &&
                        <>
                            <Spin tip="Loading" size="small">
                                <div className="content" />
                            </Spin>
                        </>}
                    </div>
                </div>
            </Flex>
        </div>
    );
};
export default ContactsTable;