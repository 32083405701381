const initialState = {
  user: {
    id: null,
    email: null,
    first_name: null
  },
  organization: null,
  token: null,
  isAuthenticated: false,
  expirationTime: null,
};

export const authReducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {

    ////////// USER LOGIN//////////
    case "LOGIN":
      console.log("STATE:", state);
      state.token = action.payload.token;
      state.isAuthenticated = action.payload.token ? true : false;
      state.user = action.payload.user;
      break;

    case "LOGOUT":
      console.log("STATE:", state);
      state.user = initialState.user;
      state.token = null;
      state.isAuthenticated = false;
      state.tasks = [];
      break;

    case "MY_PROFILE":
      console.log("STATE for My Profile:", state);
      state.user = action.payload;
      break;

    case "UPDATE_PROFILE":
      console.log("STATE", state);
      state.user = action.payload;
      break;

    case "CHANGEORG":
      state.organization = action.payload;
      break;
    // case "UPDATE_CURRENCY":
    //   state.currency = action.payload;
    //   console.log("STATE", state);
    //   break;



  }
  return state;
};
