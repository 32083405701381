

// import { ModuleCard, } from "components";
import { Card, Statistic, Row, Col, Button } from 'antd'
import { notify } from 'components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchDashboardData } from 'store/actions/AuthActions';

const Dashboard = () => {

    const [data, setData] = useState();
    const navigate = useNavigate();

    const goto = (page) => {
        navigate(page);
    } 

    const fetchData = async () => {
        try {
            const response = await fetchDashboardData();
            setData(response);
        } catch (err) {
            notify("error", "Error occurred while fetching dashboard data");
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="site-card-wrapper" style={{ marginTop: "15px" }}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Card className='cursor-pointer' title="Invoices" bordered={true} style={{ backgroundColor: "powderblue" }} onClick={()=>goto("/invoices")}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Statistic title="Total Invoices" value={data?.invoices_total} />
                                </Col>
                                {/* <Col span={12}>
                                    <Statistic title="Inactive Invoices" value={100} />
                                </Col> */}
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card className='cursor-pointer' title="Quotes" bordered={true} style={{ backgroundColor: "powderblue" }} onClick={()=>goto("/quotes")}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Statistic title="Total Quotes" value={data?.quotes_total} />
                                </Col>
                                {/* <Col span={12}>
                                    <Statistic title="Inactive Quotes" value={100} />
                                </Col> */}
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card className='cursor-pointer' title="Contacts" bordered={true} style={{ backgroundColor: "powderblue" }} onClick={()=>goto("/contacts")}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Statistic title="Total Contacts" value={data?.contacts_total} />
                                </Col>
                                {/* <Col span={12}>
                                    <Statistic title="Inactive Contacts" value={100} />
                                </Col> */}
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className="site-card-wrapper" style={{ marginTop: "15px" }}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Card className='cursor-pointer' title="Clients" bordered={true} style={{ backgroundColor: "silver" }} onClick={()=>goto("/clients")}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Statistic title="Total Clients" value={data?.clients_total} />
                                </Col>
                                {/* <Col span={12}>
                                    <Statistic title="Inactive Clients" value={100} />
                                </Col> */}
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card className='cursor-pointer' title="Vendors" bordered={true} style={{ backgroundColor: "silver" }} onClick={()=>goto("/vendors")}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Statistic title="Total Vendors" value={data?.vendors_total} />
                                </Col>
                                {/* <Col span={12}>
                                    <Statistic title="Inactive Vendors" value={100} />
                                </Col> */}
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card className='cursor-pointer' title="Employees" bordered={true} style={{ backgroundColor: "silver" }} onClick={()=>goto("/employees")}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Statistic title="Total Employees" value={data?.employees_total} />
                                </Col>
                                {/* <Col span={12}>
                                    <Statistic title="Inactive Employees" value={100} />
                                </Col> */}
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className="site-card-wrapper" style={{ marginTop: "15px" }}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Card className='cursor-pointer' title="Products" bordered={true} style={{ backgroundColor: "lavender" }} onClick={()=>goto("/products")}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Statistic title="Total Products" value={data?.products_total} />
                                </Col>
                                {/* <Col span={12}>
                                    <Statistic title="Inactive Products" value={100} />
                                </Col> */}
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card className='cursor-pointer' title="Services" bordered={true} style={{ backgroundColor: "lavender" }} onClick={()=>goto("/services")}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Statistic title="Total Services" value={data?.services_total} />
                                </Col>
                                {/* <Col span={12}>
                                    <Statistic title="Inactive Services" value={100} />
                                </Col> */}
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card className='cursor-pointer' title="Expenses" bordered={true} style={{ backgroundColor: "lavender" }} onClick={()=>goto("/expenses")}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Statistic title="Total Expenses" value={data?.expenses_total} />
                                </Col>
                                {/* <Col span={12}>
                                    <Statistic title="Inactive Expenses" value={100} />
                                </Col> */}
                            </Row>
                        </Card>
                    </Col>

                </Row>
            </div>
        </>
    );

}
export default Dashboard;