import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Table, Row, Spin, Flex } from 'antd';
import { ImportOutlined, UserAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom"
import * as actions from "store/actions/AuthActions"
import useScrollBottom from 'CustomHooks/useScrollBottom';

const ClientsTable = () => {

  const bottom = useScrollBottom();
  const { Search } = Input;

  const [searchTerm, setSearchTerm] = useState("");

  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchClients = async (page) => {
    setLoading(true);
    try {

      let response = await actions.loadClients(page, searchTerm);
      console.log({ response, dataSource });
      if (response.status == 200) {
        const data = response.data;
        setDataSource([...dataSource, ...data.results])
        setTotalRows(data.count);
      }
    } catch (e) {

    }
    setLoading(false);
  }

  const onSearch = (value) => {
    if (searchTerm === value) {

    } else {
      setSearchTerm(value);
      setDataSource([]);
      setPageNumber(0);
    }
  }

  useEffect(() => {
    let p = pageNumber + 1;
    // console.log({ next_p: p, dataSource, totalRows });
    if (p === 1) {
      fetchClients(p);
      setPageNumber(p);
    } else if (dataSource && dataSource.length < totalRows) {
      fetchClients(p);
      setPageNumber(p);
    }
  }, [bottom, searchTerm]);

  const handleDelete = async (id) => {
    const newData = dataSource.find((item) => item.id === id);
    // setDataSource(newData);
    console.log(newData)
    const response = await actions.deleteClients(
      newData.id
    );
    console.log(response);
    if (response) {
      fetchClients();
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  }

  const defaultColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a?.name - b?.name,
    },
    {
      title: 'CONTACT EMAIL',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a?.email - b?.email,
    },
    {
      title: 'ADDRESS',
      dataIndex: 'address',
      key: 'address',
      // filters: [
      //   {
      //     text: 'London',
      //     value: 'London',
      //   },
      //   {
      //     text: 'New York',
      //     value: 'New York',
      //   },
      // ],
      // onFilter: (value, record) => record.address.indexOf(value) === 0,
    },
    {
      title: 'GST NUMBER',
      dataIndex: 'gst_number',
      key: 'gst_number',
      sorter: (a, b) => a?.gst_number - b?.gst_number,
    },
    {
      title: 'MOBILE',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a?.phone - b?.phone,
    },
    {
      title: 'WEBSITE',
      dataIndex: 'website',
      key: 'website',
      sorter: (a, b) => a?.website - b?.website,
    },
    {
      title: 'BALANCE',
      dataIndex: 'balance',
      key: 'balance',
      sorter: (a, b) => a?.balance - b?.balance,
    },
    {
      title: 'PAID TO DATE',
      dataIndex: 'paid_date',
      key: 'paid_date',
      sorter: (a, b) => a?.paid_date - b?.paid_date,
    },
    {
      title: 'CREATED DATE',
      dataIndex: 'create_date',
      key: 'create_date',
      sorter: (a, b) => a?.create_date - b?.create_date,
    },
    {
      title: 'Actions',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => {
        return dataSource?.length >= 1 ? (
          <Flex gap="middle">
            <a>
              <EditOutlined
                onClick={() => navigate(`/clients/edit/${record.id}/`)}
                style={{
                  verticalAlign: 'middle',
                }}
              />
            </a>
            <Popconfirm title="Sure to delete?"
              onConfirm={() => handleDelete(record.id)}
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </Flex>
        ) : null
      }
    },
  ];

  // const handleAdd = () => {
  //   const newData = {
  //     key: count,
  //     name: `Edward King ${count}`,
  //     age: '32',
  //     address: `London, Park Lane no. ${count}`,
  //   };
  //   setDataSource([...dataSource, newData]);
  //   setCount(count + 1);
  // };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };


  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      align: "center",
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,

      }),
    };
  });

  const [selectionType, setSelectionType] = useState('checkbox');

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      let p = pageNumber + 1;
      // console.log({ next_p: p, dataSource, totalRows });
      if (p === 1) {
        fetchClients(p);
        setPageNumber(p);
      } else if (dataSource && dataSource.length < totalRows) {
        fetchClients(p);
        setPageNumber(p);
      }
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <Flex gap='large' justify='space-between'>
        <Flex gap='large' align='center'>
          <div>
            ({dataSource?.length} / {totalRows})
          </div>

          <div>
            <Search placeholder="input search text" onSearch={onSearch} style={{ width: 300 }} />
          </div>
        </Flex>
        <Flex gap='large'>
          <Button
            // onClick={handleAdd}
            onClick={() => navigate("/clients/new")}
            icon={<UserAddOutlined />}
            type="primary"
          >
            Add Client
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <div style={{ height: "75vh", overflow: "hidden", marginTop: 20 }} onScroll={handleScroll}>
          <Table
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            bordered
            size="small"
            dataSource={dataSource}
            columns={columns}
            rowKey={row => row.id}
            pagination={false}
            scrollToFirstRowOnChange
            stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
            scroll={{ y: `calc(75vh - 150px)` }}
          />
          <div style={{ margin: 20 }}>{
            loading &&
            <>
              <Spin tip="Loading" size="small">
                <div className="content" />
              </Spin>
            </>}
          </div>
        </div>
      </Flex>
    </div>
  );
};
export default ClientsTable;

