import React, { useEffect, useState } from 'react';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Select, Space } from 'antd';
import axios from 'axios';
import * as authActions from "store/actions/AuthActions";
import { useDispatch, useSelector } from 'react-redux';


const Organization = () => {

    const [organizations, setOrganizations] = useState([]);

    const dispatch = useDispatch();
    const organization = useSelector(state => state.auth.organization);

    const handleChange = (value) => {
        console.log(`selected ${value}`);
        dispatch(authActions.changeOrg(value));
    };

    const fetchOrganizations = async () => {
        const response = await authActions.loadOrganizations();
        console.log(response);
        if (response) {
            setOrganizations(response);
        } else {
            setOrganizations([]);
        }
    }

    useEffect(() => {
        fetchOrganizations();
    }, [])

    return (

        <Select
            defaultValue={organization}
            style={{
                width: 120,
            }}
            placeholder="Select Organization"
            onChange={handleChange}
            options={organizations?.map((item, index) => {
                return { value: item.id, label: item.name }
            })}
        />

    );
}

export default Organization;