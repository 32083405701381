import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Card, Row, Col, Avatar, Space, App, Typography } from 'antd';
import { login } from 'assets';
import { UserOutlined, CaretUpOutlined, LockOutlined } from '@ant-design/icons';
import { loggingIn } from 'store/actions/AuthActions';
import { useDispatch } from 'react-redux';
import * as authActions from "store/actions/AuthActions";
import { notify } from 'components';

const {Title} = Typography;

const Login = () => {

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const onFinish = async (values) => {
        // console.log('Success:', values);
        setLoading(true);
        const response = await loggingIn(values);
        if (response) {

            dispatch(authActions.login(response));
            notify("success", "Login Successful!", null);
            // notification.success({
            //     message: `Login Successfull`,
            //     description: 'Hello, Ant Design!!',
            //     placement: 'topRight',
            // });
        } else {
            notify("error", "Login Failed!", null);
        }
        setLoading(false);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        // <Row align="middle" style={{ height: "100vh" }}>
        //     <Col span={8} offset={6}>
        //         <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
        //             <h3 className='text-center'>Login Here</h3>
        //         </Space>

        //         <Form
        //             name="basic"
        //             labelCol={{
        //                 span: 8,
        //             }}
        //             wrapperCol={{
        //                 span: 16,
        //             }}
        //             initialValues={{
        //                 remember: true,
        //             }}
        //             onFinish={onFinish}
        //             onFinishFailed={onFinishFailed}
        //             autoComplete="off"
        //         >
        //             <Form.Item
        //                 label="Username"
        //                 name="email"
        //                 rules={[
        //                     {
        //                         required: true,
        //                         type: "email",
        //                         message: 'Please input valid email!',
        //                     },
        //                 ]}
        //             >
        //                 <Input />
        //             </Form.Item>

        //             <Form.Item
        //                 label="Password"
        //                 name="password"
        //                 rules={[
        //                     {
        //                         required: true,
        //                         message: 'Please input your password!',
        //                     },
        //                 ]}
        //             >
        //                 <Input.Password />
        //             </Form.Item>
        //             {/* 
        //             <Form.Item
        //                 name="remember"
        //                 valuePropName="checked"
        //                 wrapperCol={{
        //                     offset: 8,
        //                     span: 16,
        //                 }}
        //             >
        //                 <Checkbox>Remember me</Checkbox>
        //             </Form.Item> */}

        //             <Form.Item
        //                 wrapperCol={{
        //                     offset: 8,
        //                     span: 16,
        //                 }}
        //             >
        //                 <Button type="primary" htmlType="submit">
        //                     Submit
        //                 </Button>
        //             </Form.Item>
        //         </Form>
        //     </Col>
        // </Row>
        <div className="login-container">
            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col xs={{span: 20}} md={{span: 10}} lg={{span: 6}}>
                    <Card>
                        <Title level={3}>Login</Title>
                        <Form
                            name="login-form"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Please enter your username' }]}
                            >
                                <Input
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="Username"
                                />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please enter your password' }]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    placeholder="Password"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button"
                                >
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
export default Login;


