import React, { useEffect, useState } from 'react';
import { Button, Flex, Popconfirm, Table } from 'antd';
import { ImportOutlined, UserAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom"
import * as actions from "store/actions/AuthActions"
import useScrollBottom from 'CustomHooks/useScrollBottom';
import { Input } from "antd";

const ExpensesTable = () => {

  const bottom = useScrollBottom();
  const { Search } = Input;

  const [searchTerm, setSearchTerm] = useState("");

  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [selectionType, setSelectionType] = useState('checkbox');

  const fetchExpenses = async (page) => {
    const response = await actions.loadExpenses(page, searchTerm);
    console.log({ response, dataSource });
    if (response && response.results && response.results.length > 0) {

    }
    if (dataSource && dataSource.length > 0) {
      const toPut = [];
      const pushedIds = [];
      dataSource.forEach((d, i) => {
        if (!pushedIds.includes(d.id)) {
          pushedIds.push(d.id);
          toPut.push(d);
        }
      });
      response.results.forEach((rr, i) => {
        if (!pushedIds.includes(rr.id)) {
          pushedIds.push(rr.id);
          toPut.push(rr);
        }
      })
      setDataSource(toPut);
    } else {
      setTotalRows(response.count);
      setDataSource(response.results);
    }
  }

  const onSearch = (value) => {
    if (searchTerm === value) {

    } else {
      setSearchTerm(value);
      setDataSource([]);
      setPageNumber(0);
    }
  }

  useEffect(() => {
    let p = pageNumber + 1;
    // console.log({ next_p: p, dataSource, totalRows });
    if (p === 1) {
      fetchExpenses(p);
      setPageNumber(p);
    } else if (dataSource && dataSource.length < totalRows) {
      fetchExpenses(p);
      setPageNumber(p);
    }
  }, [bottom, searchTerm]);

  const handleDelete = async (id) => {
    // const newData = dataSource.find((item) => item.id === id);
    const response = await actions.deleteExpenses(id);
    if (response) {
      fetchExpenses();
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  }


  const defaultColumns = [
    {
      title: 'VENDOR',
      dataIndex: 'vendor',
      key: 'vendor',
      sorter: (a, b) => a?.vendor - b?.vendor,
    },
    {
      title: 'CLIENT',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'CATEGORY',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => a?.category - b?.category,
    },
    {
      title: 'USER',
      dataIndex: 'user',
      key: 'user',
      sorter: (a, b) => a?.user - b?.user,
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a?.date - b?.date,
    },
    {
      title: 'ORGANIZATION',
      dataIndex: 'organization',
      key: 'organization',
      sorter: (a, b) => a?.organization - b?.organization,
    },
    {
      title: 'Actions',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => {
        // console.log(record);
        return dataSource?.length >= 1 ? (
          <Flex gap="middle">
            <a>
              <EditOutlined
                onClick={() => navigate(`/expenses/edit/${record.id}/`)}
                style={{
                  verticalAlign: 'middle',
                }}
              />
            </a>
            <Popconfirm title="Sure to delete?"
              onConfirm={() => handleDelete(record.id)}
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
            <a href={`/payments?search=${record.inv_number}`}>
              Payments
            </a>
          </Flex>
        ) : null
      }
    },
  ];

  // const handleAdd = () => {
  //   const newData = {
  //     key: count,
  //     name: `Edward King ${count}`,
  //     age: '32',
  //     address: `London, Park Lane no. ${count}`,
  //   };
  //   setDataSource([...dataSource, newData]);
  //   setCount(count + 1);
  // };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };


  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div>
      <div className='d-flex align-items-center justify-content-between p-2 sticky-top custom-top'>
        <p className='ms-2 mb-0'>
          ({dataSource?.length} / {totalRows})
        </p>

        <div>
          <Search placeholder="input search text" onSearch={onSearch} style={{ width: 300 }} />
        </div>
        <div>
          <Button
            onClick={() => navigate("/expenses/import")}
            // onClick={handleAdd}
            icon={<ImportOutlined />}
            type="primary"
            style={{
              marginRight: "12px",
            }}
          >
            Import
          </Button>
          <Button
            // onClick={handleAdd}
            onClick={() => navigate("/expenses/new")}
            icon={<UserAddOutlined />}
            type="primary"
            style={{
              float: "right"
            }}
          >
            Add Expense
          </Button>
        </div>
      </div>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        bordered
        size="small"
        dataSource={dataSource}
        columns={columns}
        sticky
        pagination={false}
      // scroll={{ x: 1200 }}
      />
    </div>
  );
};
export default ExpensesTable;