import React from 'react';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { Avatar, Layout, Dropdown, Space, Row, Col } from 'antd';
import { Organization } from 'components';
import * as authActions from "store/actions/AuthActions";
import { useDispatch } from 'react-redux';


const { Header, Content, Footer, Sider } = Layout;


const Navbar = () => {

    const dispatch = useDispatch();
    const items = [
        {
            label: <a href="">My Profile</a>,
            key: '0',
        },
        {
            label: <a href="" onClick={()=> dispatch(authActions.logout())}>Logout</a>,
            key: '1',
        },
        // {
        //     type: 'divider',
        // },
        // {
        //     label: '3rd menu item',
        //     key: '3',
        // },
    ];

    return (
        <>
            <Header
                className="site-layout-background"
                style={{
                    height: "50px",
                    width: "100%"
                }}
            >
                <div style={{ float: "right", lineHeight: "50px" }}>
                    {/* <UserOutlined size={50} /> */}

                    <Dropdown
                        style={{
                        }}
                        menu={{
                            items,
                        }}
                        trigger={['click']}
                    >

                        <a onClick={(e) => e.preventDefault()}>
                            <Avatar
                                style={{
                                    // border:"1px solid white",
                                    marginRight: "9px",
                                    backgroundColor: '#001529',
                                }}
                                icon={<UserOutlined />}
                            />
                            <Space style={{ color: "#001529", marginTop: "0px" }}>
                                User
                                <DownOutlined size={30}/>
                            </Space>
                        </a>
                    </Dropdown>
                   
                </div>
                <div style={{ float: "right", lineHeight: "50px", marginRight:"10px" }}>
                <Organization />
                </div>
            </Header>
        </>
    );
}

export default Navbar;