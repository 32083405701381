import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button as BButton,
  Modal,
  Row,
  Col,
  Flex,
  Form,
  Spin,
  Divider,
  Drawer,
  Select
} from "antd";
import { useNavigate } from "react-router-dom";
import { ImportOutlined, UserAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table } from 'antd';
import { useSelector } from "react-redux";
// import * as api from "api/api";
// import { CustomTable } from "components";
// import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
// import { Document, Page } from "react-pdf";
// import { CKEditor } from "ckeditor4-react";
// import EmailEditor from 'react-email-editor';
// import { UnControlled as CodeMirror } from "react-codemirror2";
// import { getEditorConfig, page_number_position, page_sizes, page_orientation } from "utilities/config";
import "core-ui/components/TemplatePage.scss";
import * as actions from "store/actions/AuthActions"


import useScrollBottom from 'CustomHooks/useScrollBottom';
import { Input } from "antd";

import "codemirror/mode/jinja2/jinja2.js";
import "codemirror/mode/javascript/javascript.js";
// import { notify } from "components/Utilities/Notification/Notification";
import PdfPreviewModal from "components/PdfPreviewModal/PdfPreviewModal";
import TemplateForm from "pages/Template/TemplateForm";

const { Option } = Select;

const TemplatePage = (params) => {

  const bottom = useScrollBottom();
  const { Search } = Input;

  const [searchTerm, setSearchTerm] = useState("");

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [currentInstance, setCurrentInstance] = useState(null);


  const { createFormButtonText } = params;
  const [openTemplateForm, setOpenTemplateForm] = useState(false);
  const [selectionType] = useState('checkbox');
  const [dataSource, setDataSource] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [pdfPreviewData, setPdfPreviewData] = useState(null);
  const [previewPdfVisible, setPreviewPdfVisible] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [templateInfo, setTemplateInfo] = useState(null);
  const [showCodeMirror, setShowCodemirror] = useState(false);
  const [codeMirrorData, setCodeMirrorData] = useState("tehas");
  const [confirmModal, setConfirmModal] = useState("");
  const [codeMirrorLatestData, setCodeMirrorLatestData] = useState("");
  // const [template_types, setTemplateTypes] = useState(["EMAIL"]);
  const [selectedTemplateType, setSelectedTemplateType] = useState("INVOICE");
  const navigate = useNavigate();
  const template_type_ref = useRef();
  const emailEditorRef = useRef(null);

  const [header, setHeader] = useState(true);
  const [footer, setFooter] = useState(true);
  //   Refs
  const templateForm = useRef();
  const margin_top_ref = useRef();
  const margin_bottom_ref = useRef();
  const margin_left_ref = useRef();
  const margin_right_ref = useRef();
  let instance = null;

  const page_size_ref = useRef();
  const page_orientation_ref = useRef();
  const page_number_format_ref = useRef();
  const page_number_position_ref = useRef();
  const page_border_ref = useRef();

  const client = useSelector((state) => state?.authReducer?.client);

  const dataFetcher = {
    loadTemplates: async (page) => {
      const response = await actions.loadTemplates(page, searchTerm);
      console.log({ response, dataSource });
      if (response && response.results && response.results.length > 0) {
        if (dataSource && dataSource.length > 0) {
          const toPut = [];
          const pushedIds = [];
          dataSource.forEach((d, i) => {
            if (!pushedIds.includes(d.id)) {
              pushedIds.push(d.id);
              toPut.push(d);
            }
          });
          response.results.forEach((rr, i) => {
            if (!pushedIds.includes(rr.id)) {
              pushedIds.push(rr.id);
              toPut.push(rr);
            }
          })
          setDataSource(toPut);
        } else {
          setTotalRows(response.count || "0");
          setDataSource(response.results);
        }
      }
    },
    loadTemplateTypes: async () => {
      const response = await actions.loadTemplateTypes();
      console.log(response)
      if (response) {
        // setTemplateTypes(response);
      }
    }
  }

  const fetchTemplates = async (page) => {
    setLoading(true);
    try {

      let response = await actions.loadTemplates(page, searchTerm);
      console.log({ response, dataSource });
      if (response.status == 200) {
        const data = response.data;
        setDataSource([...dataSource, ...data.results])
        setTotalRows(data.count);
      }
    } catch (e) {

    }
    setLoading(false);
  }

  const events = {
    onSearch: (value) => {
      if (searchTerm === value) {

      } else {
        setSearchTerm(value);
        setDataSource([]);
        setPageNumber(0);
      }
    },
    handleDelete: async (id) => {
      const response = await actions.deleteTemplate(id);
      console.log(response);
      if (response) {
        dataFetcher.loadTemplates();
      }
    },
    handleSave: (row) => {
      const newData = [...dataSource];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setDataSource(newData);
    },
    setEditData: (r) => {
      console.log({ r });
      setSelectedTemplateType(r.template_type);
      setCurrentItem(r);
      setOpenTemplateForm(true);
    },
    onDocumentLoadSuccess: ({ numPages }) => {
      setNumPages(numPages);
    },
    handleTemplateSubmit: async (e) => {
      e.preventDefault();
      const formData = new FormData(templateForm.current);
      // formData.append("created_by", user.id);
      // formData.append("modified_by", user.id);
      if (selectedTemplateType === "EMAIL") {
        let ree = await getEmailEditorHtml();
        setTemplateInfo(ree.html);
        formData.append("html_content", ree.html);
        console.log({ design: ree.design, str: JSON.stringify(ree.design) })
        formData.append("react_email_design", JSON.stringify(ree.design));
      } else {
        formData.append("html_content", templateInfo);
      }
      formData.append("content", codeMirrorLatestData);
      formData.append("has_header", header);
      formData.append("has_footer", footer);
      const response = await actions.createTemplate(formData);
      if (response) {
        // actions.notify("Successfull!", "success");
        // setOpenCreateModal(false);
        setCurrentItem(response);
        setTemplateInfo(response.html_content);
        setCodeMirrorData(response.content);
        setCodeMirrorLatestData(response.content);
      }
    },
    handleTemplateUpdate: async (e) => {
      console.log(currentItem);
      e.preventDefault();
      const formData = new FormData(templateForm.current);
      // formData.append("created_by", user.id);
      // formData.append("modified_by", user.id);
      if (selectedTemplateType === "EMAIL") {
        let ree = await getEmailEditorHtml();
        setTemplateInfo(ree.html);
        console.log({ design: ree.design, str: JSON.stringify(ree.design) })
        formData.append("html_content", ree.html);
        formData.append("react_email_design", JSON.stringify(ree.design));
      } else {
        formData.append("html_content", templateInfo);
      }
      formData.append("content", codeMirrorLatestData);
      formData.append("has_header", header);
      formData.append("has_footer", footer);
      const response = await actions.updateTemplate(currentItem.id, formData);
      if (response) {
        // setOpenTemplateForm(false);
        setCurrentItem(response);
        // dataFetcher.loadTemplates();
        // setThisTemplate(null);
        // setOpenCreateModal(false);
      }
    },
    previewPdf: async () => {
      console.log("hello");
      const response = await actions.templateToPDFPreview(currentItem?.id);
      if (response) {
        setPdfPreviewData(response);
      }
      setPreviewPdfVisible(true);
    },
    downloadPdf: async () => {
      await actions.templateToPDF(currentItem?.id);
    },
    resetPageFormatting: () => {
      margin_top_ref.current.value = 12;
      margin_bottom_ref.current.value = 12;
      margin_left_ref.current.value = 10;
      margin_right_ref.current.value = 10;
      page_size_ref.current.value = "A4";
      page_orientation_ref.current.value = "portrait";
      page_number_format_ref.current.value = '"Page " counter(page) " of " counter(pages)';
      page_number_position_ref.current.value = "bottom-center";
      page_border_ref.current.value = "1px solid #ccc";
    },
    handleEditorLoad: (design) => {
      emailEditorRef.current.loadDesign(design);
    }
  }

  const defaultColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'DESCRPITION',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'TEMPLATE TYPE',
      dataIndex: 'template_type',
      key: 'template_type'
    },
    {
      title: 'Actions',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => {
        // console.log(record);
        return dataSource?.length >= 1 ? (
          <Flex gap="middle">
            <EditOutlined
              onClick={() => {
                setCurrentInstance(record);
                setOpenTemplateForm(true);
              }}
              style={{
                verticalAlign: 'middle',
              }}
            />
            <Popconfirm title="Sure to delete?"
            // events.handleDelete(record.id)
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </Flex>
        ) : null
      }
    },
  ];

  const antd = {

    columns: defaultColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: events.handleSave,
        }),
      };
    }),

    rowSelection: {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
    }
  }

  const getEmailEditorHtml = async () => {
    return new Promise((resolve) => {
      emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        console.log('exportHtml', html);
        const send = { design, html }
        console.log({ send });
        resolve(send);
      });
    });
  }

  useEffect(() => {
    // dataFetcher.loadTemplates();
    dataFetcher.loadTemplateTypes();
  }, []);

  useEffect(() => {
    let p = pageNumber + 1;
    // console.log({ next_p: p, dataSource, totalRows });
    if (p === 1) {
      fetchTemplates(p)
      setPageNumber(p);
    } else if (dataSource && dataSource.length < totalRows) {
      fetchTemplates(p)
      setPageNumber(p);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (currentItem) {
      console.log(currentItem);
      setTemplateInfo(currentItem.html_content);
      setCodeMirrorData(currentItem.content);
      setCodeMirrorLatestData(currentItem.content);
      setHeader(currentItem.has_header);
      setFooter(currentItem.has_footer);
    } else {
      setTemplateInfo(undefined);
      setCodeMirrorData(undefined);
      setCodeMirrorLatestData(undefined);
      setHeader(false);
      setFooter(false);
    }
  }, [currentItem]);

  useEffect(() => {
    if (showCodeMirror) {
      console.log(instance);
      instance.refresh();
    }
  }, [showCodeMirror]);

  const boldDividerStyle = {
    fontWeight: 'bold',
    fontSize: '18px', // Optional: Adjust the font size as needed
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      let p = pageNumber + 1;
      // console.log({ next_p: p, dataSource, totalRows });
      if (p === 1) {
        fetchTemplates(p);
        setPageNumber(p);
      } else if (dataSource && dataSource.length < totalRows) {
        fetchTemplates(p);
        setPageNumber(p);
      }
    }
  };


  return (
    <div style={{ padding: 10 }}>
      <Flex gap='large' justify='space-between'>
        <Flex gap='large' align='center'>
          <div className='ms-2 mb-0'>
            ({dataSource?.length} / {totalRows})
          </div>

          <div>
            <Search placeholder="input search text" onSearch={events.onSearch} style={{ width: 300 }} />
          </div>
        </Flex>
        <Flex>
          <Button
            onClick={() => {
              setCurrentItem(null);
              setOpenTemplateForm(true);
            }}
            icon={<UserAddOutlined />}
            type="primary"
            style={{
              float: "right"
            }}
          >
            {createFormButtonText}
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <div style={{ height: "75vh", overflow: "hidden", marginTop: 20 }} onScroll={handleScroll}>
          <Table
            rowSelection={{
              type: selectionType,
              ...antd.rowSelection,
            }}
            bordered
            size="small"
            dataSource={dataSource}
            columns={antd.columns}
            rowKey={row => row.id}
            pagination={false}
            scrollToFirstRowOnChange
            stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
            scroll={{ y: `calc(75vh - 150px)` }}
          />
          <div style={{ margin: 20 }}>{
            loading &&
            <>
              <Spin tip="Loading" size="small">
                <div className="content" />
              </Spin>
            </>}
          </div>
        </div>
      </Flex>
      <div
        className={`overflow-auto position-fixed w-100 h-100 fixed-top pb-4 ${openTemplateForm ? "" : "d-none"}`}
        style={{ backgroundColor: "rgb(241, 243, 250)" }}
      >
        <div className="d-flex flex-row justify-content-between bg-white py-3 mb-3">
          <h4 className="mb-0">Create Your Template</h4>

        </div>
        <div className="removeButtonRight position-absolute">
          <AiOutlineClose
            className="cursor-pointer align-self-end"
            size={20}
            onClick={() => {
              setOpenTemplateForm(false);
              setCurrentItem(null);
              setSelectedTemplateType("INVOICE");
              setShowCodemirror(false);
              templateForm.current.reset();
            }}
          />
        </div>
        <Form
          form={form}
          className=""
          // ref={templateForm}
          onFinish={(e) => {
            if (currentItem) {
              events.handleTemplateUpdate(e);
            } else {
              events.handleTemplateSubmit(e);
            }
            templateForm.current.reset();
          }}
        >
          <Row>
            <Col sm={4} lg={3} className="d-flex">
              <div className="mx-auto">
                <div className="m-2 fluid bg-white border p-3 pe-2">
                  <div className="overflow-auto scroll-height pe-2" >
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col sm={8} lg={9}>
              <div fluid>
                <div>
                  <BButton
                    className="my-3 me-2"
                    variant="success"
                    type="submit"
                    size="sm"
                  >
                    {currentItem?.id ? "UPDATE TEMPLATE" : "SUBMIT TEMPLATE"}
                  </BButton>
                  {currentItem && (
                    <BButton
                      size="sm"
                      onClick={events.downloadPdf}
                      className="my-3 mx-2"
                    >
                      Export PDF
                    </BButton>
                  )}
                  {currentItem && (
                    <BButton
                      size="sm"
                      onClick={events.previewPdf}
                      className="my-3 mx-2"
                    >
                      Preview PDF
                    </BButton>
                  )}
                  {currentItem && (
                    <BButton
                      size="sm"
                      onClick={() => {
                        setShowCodemirror(!showCodeMirror);
                      }}
                      className="my-3 mx-2"
                    >
                      {showCodeMirror
                        ? "Go to HTML Editor"
                        : "Go to Template Editor"}
                    </BButton>
                  )}
                  {currentItem && showCodeMirror && (
                    <BButton
                      size="sm"
                      onClick={() => {
                        setConfirmModal(true);
                      }}
                      className="my-3 mx-2"
                    >
                      Fetch Created HTML
                    </BButton>
                  )}
                  <div className={`${showCodeMirror ? "d-none" : "d-block"}`}>
                    {currentItem && (
                      <>
                        {
                          selectedTemplateType === "EMAIL" ?
                            <div className="w-100" style={{ width: "95% !important" }}>
                              <EmailEditor ref={emailEditorRef} onReady={() => {
                                if (currentItem) {
                                  setTemplateInfo(currentItem.html_content);
                                  events.handleEditorLoad(JSON.parse(currentItem.react_email_design));
                                }
                              }} />
                            </div> :
                            <CKEditor
                              initData={
                                currentItem
                                  ? currentItem.html_content
                                  : "<p>Create your Template here!</p>"
                              }
                              config={getEditorConfig(client, axios)}
                              editorUrl={
                                process.env.REACT_APP_BASE_URL +
                                "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"
                              }
                              onInstanceReady={() => {
                                if (currentItem) {
                                  setTemplateInfo(currentItem.html_content);
                                }
                              }}
                              onChange={(event) => {
                                const data = event.editor.getData();
                                // console.log({ event, editor, data });
                                setTemplateInfo(data);
                              }}
                            />
                        }
                      </>
                    )}
                    {!currentItem && (
                      <>
                        {
                          selectedTemplateType === "EMAIL" ?
                            <div className="w-100" style={{ width: "95% !important" }}>
                              <EmailEditor ref={emailEditorRef} onReady={() => {
                                if (currentItem) {
                                  setTemplateInfo(currentItem.html_content);
                                }
                              }} />
                            </div> :
                            <CKEditor
                              initData={"<p>Create your Template here!</p>"}
                              config={getEditorConfig(client, axios)}
                              editorUrl={
                                process.env.REACT_APP_BASE_URL +
                                "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"
                              }
                              onChange={(event) => {
                                const data = event.editor.getData();
                                // console.log({ event, editor, data });
                                setTemplateInfo(data);
                              }}
                            />
                        }
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`my-5 ${showCodeMirror ? "d-block" : "d-none"}`}
              >
                <div>
                  {showCodeMirror && (
                    <CodeMirror
                      style={{ padding: 20 }}
                      value={codeMirrorData}
                      editorDidMount={(editor) => {
                        instance = editor;
                      }}
                      options={{
                        mode: "jinja2",
                        theme: "material",
                        lineNumbers: true,
                        autoRefresh: true,
                      }}
                      onChange={(editor, data, value) => {
                        setCodeMirrorLatestData(value);
                      }}
                    />
                  )}
                </div>
              </div>
            </Col> */}
          </Row>
        </Form>
      </div>
      {/* Main Modal Div End */}

      {/* Pdf Preview Modal Start */}
      <PdfPreviewModal
        numPages={numPages}
        onDocumentLoadSuccess={events.onDocumentLoadSuccess}
        pdfPreviewData={pdfPreviewData}
        previewPdfVisible={previewPdfVisible}
        setPreviewPdfVisible={setPreviewPdfVisible}
      />
      {/* Pdf Preview Modal End */}

      {/* confirmModal Modal of flush you ckEditor Start */}
      {/* <Modal
        show={confirmModal}
        size="sm"
        aria-labelledby="contained-openTemplateForm-title-vcenter"
        centered
        onClose={() => setConfirmModal(false)}
        onHide={() => setConfirmModal(false)}
        onExited={() => {
          setConfirmModal(false);
        }}
      >
        <Modal.Body>
          <p>This will flush your CKEditor code. Do you want to proceed</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              console.log(templateInfo);
              setCodeMirrorData(templateInfo);
              setConfirmModal(false);
            }}
          >
            Confirm
          </Button>
          <Button variant="secondary" onClick={() => setConfirmModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Drawer
        title={`${currentInstance ? 'Edit' : 'Add'} Template`}
        placement="right"
        width={"75%"}
        onClose={() => { setOpenTemplateForm(false); setCurrentInstance(null); }}
        open={openTemplateForm}
        style={{ zIndex: 10 }}
      // extra={
      //   <Space>
      //     <Form layout='inline' form={lockUnlockForm}>
      //       <Form.Item label="Locked" valuePropName="checked">
      //         <Switch onChange={onFinish} checked={docToEditLock} />
      //       </Form.Item>
      //     </Form>
      //     <Button onClick={onCloseEditDrawer}>Cancel</Button>
      //   </Space>
      // }
      >
        <TemplateForm
          onClose={() => { setOpenTemplateForm(false); setCurrentInstance(null); }}
          instance={currentInstance}
          reload={() => {
            setOpenTemplateForm(false);
            // setDataSource([]);
            // setPageNumber(0);
            window.location.reload();
          }} />
      </Drawer>
    </div>
  );
};

export default TemplatePage;