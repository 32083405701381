import { ClientsTable } from "components";

const Clients = () => {



    return (
     <ClientsTable/>     
    );

}
export default Clients;