import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Switch,
  Col,
  Row,
  Card,
  Tabs,
  Space,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as adminactions from "store/actions/AuthActions";
import { useNavigate, useParams } from 'react-router-dom';
import { dateFormat } from 'constants/constants';
import moment from 'moment';
import { useSelector } from 'react-redux';

const EditPayments = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState(undefined);
  const [clients, setClients] = useState([]);
  const [paymentModes, setPaymentModes] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState(undefined);
  const [invoices, setInvoices] = useState([]);

  const organization = useSelector(state => state.auth.organization);

  const onFinish = (values) => {
    console.log('Success:', values);
    if (values) {
      onSaveHandler({ ...values, organization: organization})
    }
  };

  const loadPayment = async () => {
    if (id) {
      const response = await adminactions.loadPayment(id);
      if (response) {
        console.log({ response });
        setSelectedPaymentType(response.payment_type)
        setDataSource(response);
      }
    } else {
      setDataSource([]);
    }
  }

  const loadClients = async () => {
    const response = await adminactions.loadClients(1, undefined, 1000);
    if (response) {
      setClients(response);
    }
  }

  const onClientChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onClientSearch = (value) => {
    console.log('search:', value);
  };

  const loadPaymentModes = async () => {
    const response = await adminactions.loadPaymentModes();
    if (response) {
      setPaymentModes(response);
    }
  }

  const loadPaymentTypes = async () => {
    const response = await adminactions.loadPaymentTypes();
    if (response) {
      setPaymentTypes(response);
    }
  }

  const loadInvoices = async () => {
    const response = await adminactions.loadInvoices();
    if (response) {
      setInvoices(response);
    }
  }

  useEffect(() => {
    loadPayment();
    loadClients();
    loadInvoices();
    loadPaymentModes();
    loadPaymentTypes();
  }, []);

  const onSaveHandler = async (values) => {
    values = { ...values, payment_date: values?.payment_date.format(dateFormat) }
    console.log("VALUES", values)
    if (id) {
      //to replace undefined with nulls, so that new data is stored
      let keys = Object.keys(values);
      keys.forEach((k, i) => {
        if (!values[k]) {
          values[k] = null;
        }
      });

      const response = await adminactions.updatePayments(values, id);
      console.log(response);
      if (response) {
        // navigate("/invoices")
      }
    } else {
      const response = await adminactions.addPayments(values)
      if (response) {
        navigate('/payments')
      }
    }
  }


  return (
    <>
      {dataSource ?
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          onFinish={onFinish}
          initialValues={{ ...dataSource, payment_date: dataSource?.payment_date ? moment(dataSource?.payment_date) : null, }}
        >
          <Row
            style={{ margin: "15px" }}
          >
            <Col span={24} style={{}}>
              <div className="site-card-border-less-wrapper">
                <Card title="Details" bordered={false} style={{}}>
                  <div>
                    <Form.Item label="Client" name='client' rules={[
                      {
                        required: true,
                      },
                    ]}>
                      <Select
                        showSearch
                        placeholder="Select client"
                        optionFilterProp="children"
                        onChange={onClientChange}
                        onSearch={onClientSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={clients?.results?.map((item, index) => {
                          return { value: item.id, label: item.name }
                        })}
                      />
                    </Form.Item>
                    <Form.Item label="Payment Mode" name="payment_mode" rules={[
                      {
                        required: true,
                      },
                    ]}>
                      <Select
                        showSearch
                        placeholder="Select payment mode"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={paymentModes.map((item, index) => {
                          return { value: item[0], label: item[0] }
                        })}
                      />
                    </Form.Item>
                    <Form.Item label="Payment Type" name="payment_type" rules={[
                      {
                        required: true,
                      },
                    ]}>
                      <Select
                        showSearch
                        placeholder="Select payment type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={paymentTypes.map((item, index) => {
                          return { value: item[0], label: item[0] }
                        })}
                        onChange={(e)=>{setSelectedPaymentType(e)}}
                      />
                    </Form.Item>
                    {
                      selectedPaymentType && selectedPaymentType === "INVOICE" &&
                      <Form.Item label="Invoice" name="invoice" rules={[
                        {
                          required: true,
                        },
                      ]}>
                        <Select
                          showSearch
                          placeholder="Select invoice number"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={invoices?.results?.map((item, index) => {
                            return { value: item.id, label: item.inv_number }
                          })}
                        />
                      </Form.Item>
                    }
                    <Form.Item label="Amount Received" name="amount" rules={[
                      {
                        required: true,
                      },
                    ]}>
                      <Input />
                    </Form.Item>
                    <Form.Item label="Payment Date" name="payment_date" rules={[
                      {
                        required: true,
                      },
                    ]}>
                      <DatePicker />
                    </Form.Item>
                    <Form.Item label="Transaction Reference" name='transaction_ref'>
                      <Input />
                    </Form.Item>
                    <Form.Item label="Private Notes" name='private_notes'>
                      <TextArea />
                    </Form.Item>
                    <Form.Item label="Send Email" valuePropName="checked" name='email'>
                      <Switch />
                    </Form.Item>
                  </div>
                  <div style={{ margin: "15px" }}>
                    <Button block type="primary" htmlType="submit" >
                      Save
                    </Button>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Form>
        : <>
          {console.log("When if false")}
        </>}
    </>
  );
};
export default EditPayments;