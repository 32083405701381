import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    DatePicker,
    Form,
    Input,
    Select,
    Switch,
    Col,
    Row,
    Card,
    Space,
    Tabs,
    Table,
    Popconfirm,
    Typography,
    InputNumber, Upload, message, Flex, Drawer, notification
} from 'antd';
import fileDownload from "js-file-download";
import * as adminactions from "store/actions/AuthActions";
import { useNavigate, useParams } from 'react-router-dom';
import { InboxOutlined, CloseOutlined, DeleteOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import PdfPreviewModal from 'components/PdfPreviewModal/PdfPreviewModal';

import moment from 'moment';
import { CKEditor } from "ckeditor4-react";
import axios from 'axios';
import { DocumentPicker, DraggerBoxTable, notify } from 'components';
import { useSelector } from 'react-redux';
import { dateFormat } from 'constants/constants';
import SendInvoiceDrawer from './SendInvoiceDrawer';
import { useNotification } from 'NotificationContext';
import InvoiceProductForm from './InvoiceProductForm';
import InvoiceServiceForm from './InvoiceServiceForm';

const { Dragger } = Upload;

const InvoiceForm = () => {

    const notify = useNotification();
    const navigate = useNavigate();

    const { id } = useParams();
    const [dataSource, setDataSource] = useState(null);
    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState([]);
    const [taxRates, setTaxRates] = useState([]);
    const [productCount, setProductCount] = useState(0);
    const [serviceCount, setServiceCount] = useState(0);
    const [isManual, setIsManual] = useState(false);
    // const [products, setProducts] = useState([]);
    // const [services, setServices] = useState([]);
    const [net, setNet] = useState(0);
    // const [subtotal, setSubTotal] = useState([]);
    const [roundValue, setRoundValue] = useState(0);
    const [unRoundedTotal, setUnRoundedTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [totalTaxes, setTotalTaxes] = useState({});
    const [taxRate1, setTaxRate1] = useState(null);
    const [taxRate2, setTaxRate2] = useState(null);
    const [taxRate3, setTaxRate3] = useState(null);

    const [documents, setDocuments] = useState([]);
    const [attachments, setAttachments] = useState([]);

    const [invoiceStatuses, setInvoiceStatuses] = useState([]);

    const [previewPdfVisible, setPreviewPdfVisible] = useState(false);
    const [pdfPreviewData, setPdfPreviewData] = useState(null);
    const [numPages, setNumPages] = useState(0);

    const [mainForm] = Form.useForm();

    // const [form] = Form.useForm();
    const [productForm] = Form.useForm();
    const [openProductForm, setOpenProductForm] = useState(false);
    const [currentProductInstance, setCurrentProductInstance] = useState(null);
    const [serviceForm] = Form.useForm();
    const [openServiceForm, setOpenServiceForm] = useState(false);
    const [currentServiceInstance, setCurrentServiceInstance] = useState(null);

    const [productData, setProductData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [openSendDrawer, setOpenSendDrawer] = useState(false);
    const organization = useSelector(state => state.auth.organization);

    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.key === editingKey;

    const events = {
        onDocumentLoadSuccess: ({ numPages }) => {
            setNumPages(numPages);
        },
    }

    const onFinish = (values) => {
        console.log('Success:', values);

        // remove temporary ids from productData
        let modifiedProductData = productData;
        if (Array.isArray(productData) && productData.length > 0){
            modifiedProductData = productData?.map?.(p => {
                if (typeof p['id'] === "number") delete p['id'];
                return p;
            });
        }

        // remove temporary ids from serviceData
        let modifiedServiceData = serviceData;
        if (Array.isArray(serviceData) && serviceData.length > 0){
            modifiedServiceData = serviceData?.map?.(p => {
                if (typeof p['id'] === "number") delete p['id'];
                console.log({pajsda: p})
                return p;
            });
        }

        if (values) {
            // onSaveHandler({ ...values, organization: organization, invoice_date: values?.invoice_date.format(dateFormat), due_date: values?.due_date.format(dateFormat), invoiceitem_set: productData, invoiceservice_set: serviceData, net: net, total: total })
            onSaveHandler({ ...values, organization: organization, invoice_date: values?.invoice_date.format(dateFormat), due_date: values?.due_date.format(dateFormat), invoiceitem_set: [...modifiedProductData, ...modifiedServiceData], net: net, total: total })
        }
    };

    const loadInvoice = async () => {
        if (id) {
            const response = await adminactions.loadInvoice(id);
            console.log(response)
            if (response) {
                console.log("Changing state now...")
                setDataSource(response);
                setProductData(response.invoiceitem_set.filter((it) => it.item_type === "PRODUCT").map((item, index) => {
                    return { ...item, key: index }
                }));
                setServiceData(response.invoiceitem_set.filter((it) => it.item_type === "SERVICE").map((item, index) => {
                    return { ...item, key: index }
                }));
                // setServiceData(response.invoiceservice_set.map((item, index) => {
                //     return { ...item, key: index }
                // }));
                setDocuments(response.documents);
                setAttachments(response.attachments);
                setIsManual(response.is_manual);
                setNet(response.net);
                setTotal(response.total);
                setTaxRate1(response.tax_rate1_data);
                setTaxRate2(response.tax_rate2_data);
                setTaxRate3(response.tax_rate3_data);
            }
        }
        setLoading(false);
    }

    const loadClients = async () => {
        const response = await adminactions.loadClients(1, undefined, 1000);
        // console.log(response)
        if (response.status === 200) {
            setClients(response.data.results);
        }
    }

    const loadInvoiceStatuses = async () => {
        const response = await adminactions.loadInvoiceStatuses();
        if (response) {
            setInvoiceStatuses(response);
        }
    }

    // const loadProducts = async () => {
    //     const response = await adminactions.loadProducts();
    //     if (response) {
    //         setProducts(response.data.results);
    //     }
    // }

    // const loadServices = async () => {
    //     const response = await adminactions.loadServices();
    //     if (response) {
    //         setServices(response.data.results);
    //     }
    // }

    const loadTaxRates = async () => {
        const response = await adminactions.loadTaxRates();
        if (response) {
            setTaxRates(response);
        }
    }

    const onSaveHandler = async (values) => {
        console.log("VALUES", values)
        if (id) {
            //to replace undefined with nulls, so that new data is stored
            let keys = Object.keys(values);
            keys.forEach((k, i) => {
                if (!values[k] && values[k] !== false) {
                    values[k] = null;
                }
            });

            const response = await adminactions.updateInvoices(
                values, id
            );
            if (response.status===200) {
                // navigate("/invoices")
                // alert("Invoice updated successfully");
                message.success("Invoice updated successfully!");
            } else {
                message.error(response.data);
            }
        } else {
            const response = await adminactions.addInvoices(
                values
            )
            if (response) {
                navigate('/invoices/edit/' + response.id);
            }
        }
    }

    const getTaxes = (object) => {
        const string = []
        for (const [key, value] of object) {
            string.push({ key: key, value: value });
        }
        return string;
    }

    const onClientChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onClientSearch = (value) => {
        console.log('search:', value);
    };

    const previewDownloadPdf = async (preview, download) => {
        const response = await adminactions.downloadInvoice(id, true, "INVOICE_TEMPLATE_1");
        console.log({ response });
        if (response) {
            if (download) {
                fileDownload(response, "invoice.pdf");
            }
            if (preview) {
                setPdfPreviewData(response);
                setPreviewPdfVisible(true);
            }
        }
    }

    // const editProduct = (record) => {
    //     form.setFieldsValue({
    //         name: record.name,
    //         description: record.description,
    //         quantity: record.quantity,
    //         price: record.price,
    //         line_total: record.line_total,
    //         tax_rate1: record.tax_rate1,
    //         tax_rate2: record.tax_rate2,
    //         tax_rate3: record.tax_rate3,
    //         ...record,
    //     });
    //     setEditingKey(record.key);
    // };

    // const editService = (record) => {
    //     serviceForm.setFieldsValue({
    //         name: record.name,
    //         description: record.description,
    //         rate: record.rate,
    //         hours: record.hours,
    //         line_total: record.line_total,
    //         tax_rate1: record.tax_rate1,
    //         tax_rate2: record.tax_rate2,
    //         tax_rate3: record.tax_rate3,
    //         ...record,
    //     });
    //     setEditingKey(record.key);
    // };

    const deleteRecord = (recordkey, type) => {
        switch (type) {
            case "product":
                const newProductData = [...productData];
                const productIndex = newProductData.findIndex((item) => recordkey === item.key);
                newProductData.splice(productIndex, 1);
                setProductData(newProductData);
                break;
            case "service":
                const newServiceData = [...serviceData];
                const serviceIndex = newServiceData.findIndex((item) => recordkey === item.key);
                newServiceData.splice(serviceIndex, 1);
                setServiceData(newServiceData);
                break;
            default:
                console.error("Error occurred");
        }
    }

    const cancel = () => {
        isEditing(false);
        // form.current = null;
        setEditingKey('');
    };

    const onChange = (key) => {
        console.log(key);
        cancel();
    };

    // const saveProduct = async (key) => {
    //     try {
    //         const row = await form.validateFields();
    //         const newData = [...productData];
    //         const index = newData.findIndex((item) => key === item.key);
    //         if (index > -1) {
    //             const item = newData[index];
    //             newData.splice(index, 1, {
    //                 ...item,
    //                 ...row,
    //             });
    //             setProductData(newData);
    //             setEditingKey('');
    //         } else {
    //             newData.push(row);
    //             setProductData(newData);
    //             setEditingKey('');
    //         }
    //     } catch (errInfo) {
    //         console.log('Validate Failed:', errInfo);
    //     }
    // };

    // const saveService = async (key) => {
    //     try {
    //         const row = await serviceForm.validateFields();
    //         const newData = [...serviceData];
    //         const index = newData.findIndex((item) => key === item.key);
    //         if (index > -1) {
    //             const item = newData[index];
    //             newData.splice(index, 1, {
    //                 ...item,
    //                 ...row,
    //             });
    //             setServiceData(newData);
    //             setEditingKey('');
    //         } else {
    //             newData.push(row);
    //             setServiceData(newData);
    //             setEditingKey('');
    //         }
    //     } catch (errInfo) {
    //         console.log('Validate Failed:', errInfo);
    //     }
    // };

    // const ProductEditableCell = ({
    //     editing,
    //     dataIndex,
    //     title,
    //     inputType,
    //     record,
    //     index,
    //     children,
    //     ...restProps
    // }) => {
    //     const inputNode = inputType === 'number' ? (
    //         <InputNumber
    //             onChange={(value) => {
    //                 if (value) {
    //                     const quantity = form.getFieldValue("quantity");
    //                     const price = form.getFieldValue("price");
    //                     form.setFieldValue("line_total", quantity * price);
    //                 }
    //             }}
    //             disabled={dataIndex === "line_total"}
    //         />
    //     ) : <Input />;

    //     if (dataIndex === "title") {
    //         return (
    //             <td {...restProps}>
    //                 {editing ? (
    //                     <Form.Item
    //                         name={dataIndex}
    //                         style={{ margin: 0 }}
    //                         rules={[
    //                             {
    //                                 required: true,
    //                                 message: `Please Input ${title}!`
    //                             }
    //                         ]}
    //                     >
    //                         <Select
    //                             showSearch
    //                             placeholder="Select Product"
    //                             optionFilterProp="children"
    //                             allowClear
    //                             onChange={(value) => {
    //                                 const product = products.find(item => item.title === value);
    //                                 console.log(product);
    //                                 form.setFieldsValue({ "description": product.notes, "hsn": product.hsn, "price": product.price, "quantity": 1, "line_total": product.price });
    //                             }}
    //                             onSearch={onClientSearch}
    //                             filterOption={(input, option) =>
    //                                 (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    //                             }
    //                             options={products.map((item, index) => {
    //                                 return { value: item.title, label: item.title }
    //                             })}
    //                         />
    //                     </Form.Item>
    //                 ) : (
    //                     children
    //                 )}
    //             </td>
    //         );
    //     }

    //     return (
    //         <td {...restProps}>
    //             {editing ? (
    //                 <Form.Item
    //                     name={dataIndex}
    //                     style={{
    //                         margin: 0,
    //                     }} restProps
    //                     rules={[
    //                         {
    //                             required: !['tax_rate1', 'tax_rate2', 'tax_rate3', 'description'].includes(dataIndex),
    //                             message: `Please Input ${title}!`
    //                         },
    //                     ]}
    //                 >
    //                     {['tax_rate1', 'tax_rate2', 'tax_rate3'].includes(dataIndex) ? <Select
    //                         showSearch
    //                         placeholder="Select Tax Rate"
    //                         optionFilterProp="children"
    //                         allowClear
    //                         onChange={(value) => {
    //                             const taxRate = taxRates.find(item => item.title === value);
    //                             console.log(taxRate);
    //                             // form.setFieldsValue({ "description": product.notes, "price": product.price, "quantity": 1, "line_total": product.price });
    //                         }}
    //                         onSearch={onClientSearch}
    //                         filterOption={(input, option) =>
    //                             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    //                         }
    //                         options={taxRates.map((item, index) => {
    //                             return { value: item.id, label: `${item.title + " " + item.rate} %` }
    //                         })}
    //                     /> : inputNode}
    //                 </Form.Item>
    //             ) : (
    //                 children
    //             )}
    //         </td>
    //     );
    // };

    // const ServiceEditableCell = ({
    //     editing,
    //     dataIndex,
    //     title,
    //     inputType,
    //     record,
    //     index,
    //     children,
    //     ...restProps
    // }) => {
    //     const inputNode = inputType === 'number' ? (
    //         <InputNumber
    //             onChange={(value) => {
    //                 if (value) {
    //                     const hours = serviceForm.getFieldValue("hours");
    //                     const rate = serviceForm.getFieldValue("rate");
    //                     // alert(quantity*price);
    //                     serviceForm.setFieldValue("line_total", hours * rate);
    //                 }
    //             }}
    //             disabled={dataIndex === "line_total"}
    //         />
    //     ) : <Input />;

    //     if (dataIndex === "title") {
    //         return (

    //             <td {...restProps}>
    //                 {editing ? (
    //                     <Form.Item
    //                         name={dataIndex}
    //                         style={{
    //                             margin: 0,
    //                         }}
    //                         rules={[
    //                             {
    //                                 required: true,
    //                                 message: `Please Input ${title}!`
    //                             },
    //                         ]}
    //                     >
    //                         <Select
    //                             showSearch
    //                             placeholder="Select Service"
    //                             optionFilterProp="children"
    //                             allowClear
    //                             onChange={(value) => {
    //                                 const service = services.find(item => item.title === value);
    //                                 console.log(service);
    //                                 serviceForm.setFieldsValue({ "description": service.notes, "rate": service.rate, "hsn": service.hsn, "hours": service.hours, "line_total": service.rate * service.hours });
    //                             }}
    //                             onSearch={onClientSearch}
    //                             filterOption={(input, option) =>
    //                                 (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    //                             }
    //                             options={services.map((item, index) => {
    //                                 return { value: item.title, label: item.title }
    //                             })}
    //                         />
    //                     </Form.Item>
    //                 ) : (
    //                     children
    //                 )}
    //             </td>
    //         );
    //     }

    //     return (
    //         <td {...restProps}>
    //             {editing ? (
    //                 <Form.Item
    //                     name={dataIndex}
    //                     style={{
    //                         margin: 0,
    //                     }}
    //                     rules={[
    //                         {
    //                             required: !['tax_rate1', 'tax_rate2', 'tax_rate3', 'description'].includes(dataIndex),
    //                             message: `Please Input ${title}!`
    //                         },
    //                     ]}
    //                 >
    //                     {['tax_rate1', 'tax_rate2', 'tax_rate3'].includes(dataIndex) ? <Select
    //                         showSearch
    //                         placeholder="Select Tax Rate"
    //                         optionFilterProp="children"
    //                         allowClear
    //                         onChange={(value) => {
    //                             const taxRate = taxRates.find(item => item.title === value);
    //                             console.log(taxRate);
    //                             // form.setFieldsValue({ "description": product.notes, "price": product.price, "quantity": 1, "line_total": product.price });
    //                         }}
    //                         onSearch={onClientSearch}
    //                         filterOption={(input, option) =>
    //                             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    //                         }
    //                         options={taxRates.map((item, index) => {
    //                             return { value: item.id, label: `${item.title + " " + item.rate} %` }
    //                         })}
    //                     /> : inputNode}
    //                 </Form.Item>
    //             ) : (
    //                 children
    //             )}
    //         </td>
    //     );
    // };

    // const handleAddProduct = () => {
    //     const newData = {
    //         key: productCount,
    //         name: '',
    //         description: '',
    //         quantity: 0,
    //         price: 0,
    //         line_total: 0,
    //     };
    //     setProductData([...productData, newData]);
    // };

    // const handleAddService = () => {
    //     const newData = {
    //         key: serviceCount,
    //         name: '',
    //         description: '',
    //         hours: 0,
    //         rate: 0,
    //         line_total: 0,
    //     };
    //     setServiceData([...serviceData, newData]);
    // };

    const handleAddDocument = async (doc_ids = []) => {
        const response = await adminactions.addDocumentsToInvoice(id, doc_ids);
        if (response) {
            setDocuments(response);
        }
    }

    const handleDeleteDocument = async (doc_id) => {
        const response = await adminactions.removeDocumentFromInvoice(id, doc_id);
        if (response) {
            setDocuments(response);
        }
    }

    const handleDeleteAttachment = async (doc_id) => {
        const response = await adminactions.removeDocumentFromInvoice(id, doc_id);
        if (response) {
            setAttachments(response);
        }
    }

    const props = {
        name: 'file',
        multiple: false,
        action: process.env.REACT_APP_BASE_URL + `/invoices/invoices/${id}/addDoc/`,
        headers: { "Authorization": axios.defaults.headers.common['Authorization'] },
        onChange(info) {
            console.log(info);
            const { status, response } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }

            if (response) {
                setAttachments(response);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const viewDownloadDocument = async (record, preview, download) => {
        try {
            const response = await axios.get(record.document['document_file'], {
                responseType: "blob",
            });
            // console.log({ response, record });
            if (response.status === 200) {
                if (download) {
                    fileDownload(response.data, record.document['name']);
                }
                if (preview) {
                    if (record.document['extension'] === ".pdf") {
                        setPdfPreviewData(response.data);
                        setPreviewPdfVisible(true);
                    } else {
                        notify("error", "only pdfs can be rendered for now");
                    }
                }
            }
        } catch (err) {
            console.log("Error: ", err);
        }
    }

    const productColumns = [
        {
            title: 'Item',
            dataIndex: 'title',
            width: '10%',
            // editable: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '26%',
            // editable: true,
            render: (text, record, index) => {
                return <div dangerouslySetInnerHTML={{ __html: record.description }} />
            }
        },
        {
            title: 'HSN/SAC',
            dataIndex: 'hsn',
            width: '8%',
            // editable: true,
        },
        {
            title: 'Price/unit',
            dataIndex: 'price',
            width: '8%',
            // editable: true,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: '8%',
            // editable: true,
            render: (text, record, index) => {
                if (record?.is_fixed_rate) {
                    return <div>FIXED RATE</div>;
                } else {
                    return record?.quantity;
                }
            }
        },
        {
            title: 'Tax Rate',
            dataIndex: 'tax_rate1',
            width: '8%',
            // editable: true,
            render: (text, record, index) => {
                const taxRate = taxRates.find(item => item.id === text)
                if (taxRate) {
                    return `${taxRate?.title + " " + taxRate?.rate} %`
                } return null;
            }
        },
        {
            title: 'Tax Rate',
            dataIndex: 'tax_rate2',
            width: '8%',
            // editable: true,
            render: (text, record, index) => {
                const taxRate = taxRates.find(item => item.id === text)
                if (taxRate) {
                    return `${taxRate?.title + " " + taxRate?.rate} %`
                } return null;
            }
        },
        {
            title: 'Tax Rate',
            dataIndex: 'tax_rate3',
            width: '8%',
            // editable: true,
            render: (text, record, index) => {
                const taxRate = taxRates.find(item => item.id === text)
                if (taxRate) {
                    return `${taxRate?.title + " " + taxRate?.rate} %`
                } return null;
            }
        },
        {
            title: 'Line Total',
            dataIndex: 'line_total',
            width: '10%',
            // editable: true,
            // render: (text, record, index) => {
            //     return <div>{record.price * record.quantity}</div>
            // }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            width: '6%',
            render: (_, record) => {
                return (
                    <span>
                        <Typography.Link
                            onClick={() => {
                                setCurrentProductInstance(record)
                                setOpenProductForm(true)
                            }}
                        >
                            Edit
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => deleteRecord(record.key, "product")}>
                            <a style={{ marginLeft: 10 }}>Delete</a>
                        </Popconfirm>
                    </span>
                )
                // const editable = isEditing(record);
                // return editable ? (
                //     <span>
                //         <Typography.Link
                //             onClick={() => saveProduct(record.key)}
                //             style={{
                //                 marginRight: 8,
                //             }}
                //         >
                //             Save
                //         </Typography.Link>
                //         <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                //             <a>Cancel</a>
                //         </Popconfirm>
                //     </span>
                // ) : (
                //     <span>
                //         <Typography.Link disabled={editingKey !== ''} onClick={() => editProduct(record)}>
                //             Edit
                //         </Typography.Link>
                //         <Popconfirm title="Sure to delete?" onConfirm={() => deleteRecord(record.key, "product")}>
                //             <a style={{ marginLeft: 10 }}>Delete</a>
                //         </Popconfirm>
                //     </span>
                // );
            },
        },
    ];

    const serviceColumns = [
        {
            title: 'Item',
            dataIndex: 'title',
            width: '10%',
            // editable: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '26%',
            // editable: true,
            render: (text, record, index) => {
                return <div dangerouslySetInnerHTML={{ __html: record.description }} />
            }
        },
        {
            title: 'HSN/SAC',
            dataIndex: 'hsn',
            width: '8%',
            // editable: true,
        },
        {
            title: 'Rate',
            dataIndex: 'price',
            width: '8%',
            // editable: true,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: '8%',
            // editable: true,
            render: (text, record, index) => {
                if (record?.is_fixed_rate) {
                    return <div>FIXED RATE</div>;
                } else {
                    return <div>{record?.quantity} {record?.unit}</div>;
                }
            }
        },
        {
            title: 'Tax Rate',
            dataIndex: 'tax_rate1',
            width: '8%',
            // editable: true,
            render: (text, record, index) => {
                const taxRate = taxRates.find(item => item.id === text)
                if (taxRate) {
                    return `${taxRate?.title + " " + taxRate?.rate} %`
                } return null;
            }
        },
        {
            title: 'Tax Rate',
            dataIndex: 'tax_rate2',
            width: '8%',
            // editable: true,
            render: (text, record, index) => {
                const taxRate = taxRates.find(item => item.id === text)
                if (taxRate) {
                    return `${taxRate?.title + " " + taxRate?.rate} %`
                } return null;
            }
        },
        {
            title: 'Tax Rate',
            dataIndex: 'tax_rate3',
            width: '8%',
            // editable: true,
            render: (text, record, index) => {
                const taxRate = taxRates.find(item => item.id === text)
                if (taxRate) {
                    return `${taxRate?.title + " " + taxRate?.rate} %`
                } return null;
            }
        },
        {
            title: 'Line Total',
            dataIndex: 'line_total',
            width: '10%',
            // editable: true,
            // render: (text, record, index) => {
            //     return <div>{record.rate * record.hours}</div>
            // }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            width: '6%',
            render: (_, record) => {
                return (
                    <span>
                        <Typography.Link
                            onClick={() => {
                                setCurrentServiceInstance(record)
                                setOpenServiceForm(true)
                            }}
                        >
                            Edit
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => deleteRecord(record.key, "service")}>
                            <a style={{ marginLeft: 10 }}>Delete</a>
                        </Popconfirm>
                    </span>
                )
                // const editable = isEditing(record);
                // return editable ? (
                //     <span>
                //         <Typography.Link
                //             onClick={() => saveService(record.key)}
                //             style={{
                //                 marginRight: 8,
                //             }}
                //         >
                //             Save
                //         </Typography.Link>
                //         <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                //             <a>Cancel</a>
                //         </Popconfirm>
                //     </span>
                // ) : (
                //     <span>
                //         <Typography.Link disabled={editingKey !== ''} onClick={() => editService(record)}>
                //             Edit
                //         </Typography.Link>
                //         <Popconfirm title="Sure to delete?" onConfirm={() => deleteRecord(record.key, "service")}>
                //             <a style={{ marginLeft: 10 }}>Delete</a>
                //         </Popconfirm>
                //     </span>
                // );
            },
        },
    ];

    const documentColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a?.id.localeCompare(b?.id),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a?.name.localeCompare(b?.name),
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => moment(a?.created_at) - moment(b?.created_at),
            render: (text, record) => {
                return <div>{moment(text).format('MMMM Do YYYY, h:mm:ss a')}</div>
            }
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            sorter: (a, b) => a?.org_size - b?.org_size,
        },
        {
            title: 'Linked',
            dataIndex: 'is_linked',
            key: 'is_linked',
            sorter: (a, b) => a?.is_linked - b?.is_linked,
            render: (_, record) => {
                const size = 8;
                const radius = "30%";
                return (
                    <div className='d-flex justify-content-center align-items-center'>
                        {record.is_linked ? <>
                            <div style={{ width: size, height: size, backgroundColor: "green", borderRadius: radius }}>

                            </div>
                        </> : <>
                            <div style={{ width: size, height: size, backgroundColor: "red", borderRadius: radius }}>

                            </div>
                        </>}
                    </div>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            render: (_, record) => {
                return (
                    <span>
                        <Typography.Link onClick={() => { console.log("View Document"); viewDownloadDocument(record, true, false); }}>
                            View
                        </Typography.Link>
                        <Typography.Link className='ms-2' onClick={() => { console.log("Download Document"); viewDownloadDocument(record, false, true); }}>
                            Download
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteDocument(record.id)}>
                            <a style={{ marginLeft: 10 }}>Delete</a>
                        </Popconfirm>
                    </span>
                );
            },
        },
    ];

    const attachmentColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a?.id.localeCompare(b?.id),
        },
        {
            title: 'Name',
            dataIndex: ["document", "name"],
            key: 'name',
            sorter: (a, b) => a?.name.localeCompare(b?.name),
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => moment(a?.created_at) - moment(b?.created_at),
            render: (text, record) => {
                return <div>{moment(text).format('MMMM Do YYYY, h:mm:ss a')}</div>
            }
        },
        {
            title: 'Size',
            dataIndex: ["document", "size"],
            key: 'size',
            sorter: (a, b) => a?.org_size - b?.org_size,
        },
        {
            title: 'Linked',
            dataIndex: ["document", "is_linked"],
            key: 'is_linked',
            sorter: (a, b) => a?.is_linked - b?.is_linked,
            render: (_, record) => {
                const size = 8;
                const radius = "30%";
                return (
                    <div className='d-flex justify-content-center align-items-center'>
                        {record.is_linked ? <>
                            <div style={{ width: size, height: size, backgroundColor: "green", borderRadius: radius }}>

                            </div>
                        </> : <>
                            <div style={{ width: size, height: size, backgroundColor: "red", borderRadius: radius }}>

                            </div>
                        </>}
                    </div>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            render: (_, record) => {
                return (
                    <Flex gap="middle">
                        <Typography.Link onClick={() => { console.log("View Document"); viewDownloadDocument(record, true, false); }}>
                            <EyeOutlined />
                        </Typography.Link>
                        <Typography.Link onClick={() => { console.log("Download Document"); viewDownloadDocument(record, false, true); }}>
                            <DownloadOutlined />
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteAttachment(record.id)}>
                            <DeleteOutlined />
                        </Popconfirm>
                    </Flex>
                );
            },
        },
    ];

    const mergedColumns = productColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: ["price", "quantity", "line_total"].includes(col.dataIndex) ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const mergedServiceColumns = serviceColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: ["rate", "hours", "line_total"].includes(col.dataIndex) ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const editorConfig = {
        extraPlugins: ["tableresize", "uploadimage", "createpdf"],
        removePlugins: [
            "exportpdf",
            "forms",
            "smiley",
            "language",
            "iframe",
            "about",
        ],
        // filebrowserBrowseUrl: 'http://localhost:4000/media/24b358cf-8e56-4b03-9843-29fabf589b8d/template/home-advt_jzx7KBU.jpeg',
        filebrowserImageUploadUrl:
            process.env.REACT_APP_BASE_URL +
            `/template/upload_template_image/`,
        fileTools_requestHeaders: axios.defaults.headers.common,
        uploadUrl:
            process.env.REACT_APP_BASE_URL +
            `/template/upload_template_image/`,
        contentsCss: [
            // "https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css",
            process.env.REACT_APP_BASE_URL +
            "/staticfiles/ckeditor/4.19.0/full-all/document-style.css",
        ],
        bodyClass: "document-editor",
        // height: 900,
        // width: 1000,
        height: "5cm",
        // width: "18.6cm",
        width: "21cm",
        allowedContent: true,
        // extraPlugins: [
        //     'timestamp'
        // ]
        // exportPdf_options: {
        //     header_html: '<div class="styled">This is the Header</div>',
        //     footer_html: '<div class="styled-counter"><span class="date></span></div>',
        //     header_and_footer_css: '.styled { font-weight: bold; padding: 10px; display: flex; color: red; } .styled-counter {font-size: 10px; color: red; }',
        //     margin_top: '2cm',
        //     margin_bottom: '10cm',
        // }
        // exportPdf_options: {
        //     format: "A3"
        // }
        exportPdf_options: {
            header_html: '<div class="styled">Header content</div>',
            footer_html: '<div class="styled"><span class="pageNumber"></span></div>',
            header_and_footer_css:
                ".styled { font-weight: bold; padding: 10px; text-align: center; color: red; }",
            margin_left: "1cm",
            margin_right: "2cm",
            format: "A5",
            page_orientation: "landscape",
        },
        protectedSource: [/{%[\s\S]*?%}+/g, /{{[\s\S]*?}}+/g],
        // formatOutput: false,
        // formatSource: false
    };

    useEffect(() => {
        loadTaxRates();
        loadInvoice();
        loadClients();
        // loadProducts();
        // loadServices();
        loadInvoiceStatuses();

    }, []);

    useEffect(() => {
        setProductCount(productData.length);
    }, [productData]);

    useEffect(() => {
        setServiceCount(serviceData.length);
    }, [serviceData]);

    useEffect(() => {
        if (isManual) {
            let tempNet = net;
            setProductData([]);
            setServiceData([]);
            if (!id) {
                mainForm.setFieldValue('net', tempNet);
            }
        } else {
            //TODO
        }
    }, [isManual]);

    useEffect(() => {
        let tempNet = 0, itemTaxes = 0, tempSubTotal = 0, tempTotal = 0;
        let tempTotalTaxes = {};
        if (isManual) {
            tempNet = parseFloat(net);
            if (isNaN(tempNet)) {
                tempNet = 0;
            }
        }
        if (productData.length > 0 || serviceData.length > 0) {
            console.log(productData);
            // setNet()
            // let tempNet = productData?.reduce((n, currentRecord) => {
            //     return (n + currentRecord.line_total);
            // }, 0);
            setNet(tempNet);
            for (let i in productData) {
                tempNet += productData[i].line_total;
                console.log(productData[i].tax_rate1);
                if (productData[i].tax_rate1) {
                    console.log(taxRates);
                    const taxObj = taxRates.find(item => item.id === productData[i].tax_rate1);
                    console.log(taxObj);
                    if (taxObj) {
                        if (taxObj.title in tempTotalTaxes) {
                            tempTotalTaxes[taxObj.title] += productData[i].line_total * taxObj.rate / 100;
                        } else {
                            tempTotalTaxes[taxObj.title] = productData[i].line_total * taxObj.rate / 100;
                        }
                    }
                }
                if (productData[i].tax_rate2) {
                    console.log(taxRates);
                    const taxObj = taxRates.find(item => item.id === productData[i].tax_rate2);
                    console.log(taxObj);
                    if (taxObj) {
                        if (taxObj.title in tempTotalTaxes) {
                            tempTotalTaxes[taxObj.title] += productData[i].line_total * taxObj.rate / 100;
                        } else {
                            tempTotalTaxes[taxObj.title] = productData[i].line_total * taxObj.rate / 100;
                        }
                    }
                }
                if (productData[i].tax_rate3) {
                    console.log(taxRates);
                    const taxObj = taxRates.find(item => item.id === productData[i].tax_rate3);
                    console.log(taxObj);
                    if (taxObj) {
                        if (taxObj.title in tempTotalTaxes) {
                            tempTotalTaxes[taxObj.title] += productData[i].line_total * taxObj.rate / 100;
                        } else {
                            tempTotalTaxes[taxObj.title] = productData[i].line_total * taxObj.rate / 100;
                        }
                    }
                }
            }
            for (let i in serviceData) {
                tempNet += serviceData[i].line_total;
                console.log(serviceData[i].tax_rate1);
                if (serviceData[i].tax_rate1) {
                    console.log(taxRates);
                    const taxObj = taxRates.find(item => item.id === serviceData[i].tax_rate1);
                    console.log(taxObj);
                    if (taxObj) {
                        if (taxObj.title in tempTotalTaxes) {
                            tempTotalTaxes[taxObj.title] += serviceData[i].line_total * taxObj.rate / 100;
                        } else {
                            tempTotalTaxes[taxObj.title] = serviceData[i].line_total * taxObj.rate / 100;
                        }
                    }
                }
                if (serviceData[i].tax_rate2) {
                    console.log(taxRates);
                    const taxObj = taxRates.find(item => item.id === serviceData[i].tax_rate2);
                    console.log(taxObj);
                    if (taxObj) {
                        if (taxObj.title in tempTotalTaxes) {
                            tempTotalTaxes[taxObj.title] += serviceData[i].line_total * taxObj.rate / 100;
                        } else {
                            tempTotalTaxes[taxObj.title] = serviceData[i].line_total * taxObj.rate / 100;
                        }
                    }
                }
                if (serviceData[i].tax_rate3) {
                    console.log(taxRates);
                    const taxObj = taxRates.find(item => item.id === serviceData[i].tax_rate3);
                    console.log(taxObj);
                    if (taxObj) {
                        if (taxObj.title in tempTotalTaxes) {
                            tempTotalTaxes[taxObj.title] += serviceData[i].line_total * taxObj.rate / 100;
                        } else {
                            tempTotalTaxes[taxObj.title] = serviceData[i].line_total * taxObj.rate / 100;
                        }
                    }
                }
            }
            for (const [key, value] of Object.entries(tempTotalTaxes)) {
                itemTaxes += value;
            }
            let netTaxes = 0;
            if (taxRate1) {
                const taxObj = taxRates.find(item => item.id === taxRate1.id);
                console.log(taxObj);
                if (taxObj) {
                    if (taxObj.title in tempTotalTaxes) {
                        tempTotalTaxes[taxObj.title] += tempNet * taxObj.rate / 100;
                    } else {
                        tempTotalTaxes[taxObj.title] = tempNet * taxObj.rate / 100;
                    }
                    netTaxes += tempNet * taxObj.rate / 100;
                }
            }
            if (taxRate2) {
                const taxObj = taxRates.find(item => item.id === taxRate2.id);
                console.log(taxObj);
                if (taxObj) {
                    if (taxObj.title in tempTotalTaxes) {
                        tempTotalTaxes[taxObj.title] += tempNet * taxObj.rate / 100;
                    } else {
                        tempTotalTaxes[taxObj.title] = tempNet * taxObj.rate / 100;
                    }
                    netTaxes += tempNet * taxObj.rate / 100;
                }
            }
            if (taxRate3) {
                const taxObj = taxRates.find(item => item.id === taxRate3.id);
                console.log(taxObj);
                if (taxObj) {
                    if (taxObj.title in tempTotalTaxes) {
                        tempTotalTaxes[taxObj.title] += tempNet * taxObj.rate / 100;
                    } else {
                        tempTotalTaxes[taxObj.title] = tempNet * taxObj.rate / 100;
                    }
                    netTaxes += tempNet * taxObj.rate / 100;
                }
            }
            setNet(tempNet);
            setTotalTaxes(tempTotalTaxes);
            // setSubTotal(tempNet + itemTaxes);
            let urt = parseFloat(tempNet + itemTaxes + netTaxes).toFixed(2)
            let tot = Math.round(urt);
            let rof = (tot - urt).toFixed(2);
            setUnRoundedTotal(urt);
            setRoundValue(rof);
            setTotal(tot);
        } else {
            // setSubTotal(0);
            let netTaxes = 0;
            if (taxRate1) {
                const taxObj = taxRates.find(item => item.id === taxRate1.id);
                console.log(taxObj);
                if (taxObj) {
                    if (taxObj.title in tempTotalTaxes) {
                        tempTotalTaxes[taxObj.title] += tempNet * taxObj.rate / 100;
                    } else {
                        tempTotalTaxes[taxObj.title] = tempNet * taxObj.rate / 100;
                    }
                    netTaxes += tempNet * taxObj.rate / 100;
                }
            }
            if (taxRate2) {
                const taxObj = taxRates.find(item => item.id === taxRate2.id);
                console.log(taxObj);
                if (taxObj) {
                    if (taxObj.title in tempTotalTaxes) {
                        tempTotalTaxes[taxObj.title] += tempNet * taxObj.rate / 100;
                    } else {
                        tempTotalTaxes[taxObj.title] = tempNet * taxObj.rate / 100;
                    }
                    netTaxes += tempNet * taxObj.rate / 100;
                }
            }
            if (taxRate3) {
                const taxObj = taxRates.find(item => item.id === taxRate3.id);
                console.log(taxObj);
                if (taxObj) {
                    if (taxObj.title in tempTotalTaxes) {
                        tempTotalTaxes[taxObj.title] += tempNet * taxObj.rate / 100;
                    } else {
                        tempTotalTaxes[taxObj.title] = tempNet * taxObj.rate / 100;
                    }
                    netTaxes += tempNet * taxObj.rate / 100;
                }
            }
            console.log({
                ...dataSource,
                invoice_date: dataSource?.invoice_date ? moment(dataSource?.invoice_date) : null,
                due_date: dataSource?.due_date ? moment(dataSource?.due_date) : null,
            })
            console.log({ tempNet, tempTotalTaxes, tempTotal, itemTaxes, netTaxes });
            setNet(tempNet);
            setTotalTaxes(tempTotalTaxes);
            // setSubTotal(tempNet + itemTaxes);
            let urt = parseFloat(tempNet + itemTaxes + netTaxes).toFixed(2)
            let tot = Math.round(urt);
            let rof = (tot - urt).toFixed(2);
            setUnRoundedTotal(urt);
            setRoundValue(rof);
            setTotal(tot);
        }
    }, [productData, serviceData, taxRate1, taxRate2, taxRate3, net]);


    return (
        <div className='custom-scroll-div' style={{ overflowY: "scroll", maxHeight: "90vh", padding: 10, paddingBottom: 30 }}>
            {!loading && <Form
                form={mainForm}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                onFinish={onFinish}
                initialValues={{
                    ...dataSource,
                    invoice_date: dataSource?.invoice_date ? moment(dataSource?.invoice_date) : null,
                    due_date: dataSource?.due_date ? moment(dataSource?.due_date) : null,
                }}
            >
                <Flex justify='end' gap='middle' style={{ margin: "10px auto" }}>
                    {/* <Button htmlType="button" onClick={() => { previewDownloadPdf(true, false) }}>
                            Preview PDF
                        </Button> */}
                    {id && <>
                        {!isManual && <Button htmlType="button" onClick={() => { previewDownloadPdf(false, true) }}>
                            Download Invoice
                        </Button>}
                        <Button htmlType="button" onClick={() => setOpenSendDrawer(true)}>
                            Send Invoice
                        </Button>
                    </>}
                    <Button type="primary" htmlType="submit" >
                        Save
                    </Button>
                </Flex>

                <Row>
                    <Col span={8}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="Client" bordered={false}>
                                <div>
                                    <Form.Item label="Client" name='client' rules={[{ required: true }]}>
                                        <Select
                                            showSearch
                                            placeholder="Select Client"
                                            optionFilterProp="children"
                                            onChange={onClientChange}
                                            onSearch={onClientSearch}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={clients?.map((item, index) => {
                                                return { value: item.id, label: item.name }
                                            })}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item label="Status" name='status' rules={[{ required: true }]}>
                                        <Select
                                            placeholder="Select Status"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={invoiceStatuses.map((item, index) => {
                                                return { value: item[0], label: item[0] }
                                            })}
                                        />
                                    </Form.Item>
                                </div>
                            </Card>
                        </div>
                    </Col>
                    <Col span={8} style={{ padding: "0px 0px 10px 10px" }}>
                        <div className="site-card-border-less-wrapper">
                            <Card bordered={false}>
                                <Form.Item label="Invoice Date" name='invoice_date'>
                                    <DatePicker />
                                </Form.Item>
                                <Form.Item label="Due Date" name='due_date'>
                                    <DatePicker />
                                </Form.Item>
                            </Card>
                        </div>
                    </Col>
                    <Col span={8} style={{ padding: "0px 0px 10px 10px" }}>
                        <div className="site-card-border-less-wrapper">
                            <Card bordered={false}>
                                <Form.Item label="Invoice Number" name='inv_number' labelCol={{ span: 8 }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="PO Number" name='po_number' labelCol={{ span: 8 }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Is Manual" name='is_manual' valuePropName="checked" labelCol={{ span: 8 }}>
                                    <Switch onChange={value => setIsManual(value)} disabled={id ? true : false} />
                                </Form.Item>
                            </Card>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {!isManual && <div className="site-card-border-less-wrapper">
                            <Card bordered={false} style={{}}>
                                <Tabs
                                    defaultActiveKey="1"
                                    onChange={onChange}
                                    items={[
                                        {
                                            label: `Products`,
                                            key: '1',
                                            children:
                                                <div>
                                                    <Form form={productForm} component={false}>
                                                        <Table
                                                            // components={{
                                                            //     body: {
                                                            //         cell: ProductEditableCell,
                                                            //     },
                                                            // }}
                                                            dataSource={productData}
                                                            columns={mergedColumns}
                                                            rowClassName="editable-row"
                                                            size="small"
                                                            pagination={false}
                                                            rowKey={row => row.id}
                                                        />
                                                        <Button
                                                            // onClick={handleAddProduct}
                                                            onClick={() => setOpenProductForm(true)}
                                                            type="primary"
                                                            style={{
                                                                marginBottom: 16,
                                                            }}
                                                        >
                                                            Add Item
                                                        </Button>
                                                    </Form>
                                                </div>,
                                        },
                                        {
                                            label: `Services`,
                                            key: '2',
                                            children:
                                                <div>
                                                    <Form form={serviceForm} component={false}>
                                                        <Table
                                                            // components={{
                                                            //     body: {
                                                            //         cell: ServiceEditableCell,
                                                            //     },
                                                            // }}
                                                            dataSource={serviceData}
                                                            columns={mergedServiceColumns}
                                                            rowClassName="editable-row"
                                                            size="small"
                                                            pagination={false}
                                                            rowKey={row => row.id}
                                                        />
                                                        <Button
                                                            // onClick={handleAddService}
                                                            onClick={() => setOpenServiceForm(true)}
                                                            type="primary"
                                                            style={{
                                                                marginBottom: 16,
                                                            }}
                                                        >
                                                            Add Item
                                                        </Button>
                                                    </Form>
                                                </div>,
                                        },
                                    ]}
                                />
                            </Card>
                        </div>}
                        {isManual && <div className="site-card-border-less-wrapper">
                            <Row>
                                <Col span={8}>
                                    <Card bordered={false}>
                                        <Form.Item label="Net" name='net' >
                                            <Input onChange={(e) => {
                                                setNet(e.target.value);
                                            }} />
                                        </Form.Item>
                                    </Card>
                                </Col>
                            </Row>
                        </div>}
                    </Col>
                </Row>
                <Row style={{ padding: "10px 0px 0px 0px" }}>
                    <Col span={16}>
                        {id && <div className="site-card-border-less-wrapper">
                            <Card bordered={false} style={{}}>
                                <Tabs
                                    defaultActiveKey="1"
                                    onChange={onChange}
                                    items={[
                                        {
                                            label: `Public Notes`,
                                            key: '1',
                                            children:
                                                (<div
                                                >
                                                    <Form.Item valuePropName='data' name="public_notes"
                                                        getValueFromEvent={(event) => {
                                                            const data = event.editor.getData();
                                                            return data;
                                                        }}>
                                                        <CKEditor
                                                            initData={
                                                                dataSource
                                                                    ? dataSource.public_notes
                                                                    : "<p>Create your Template here!</p>"
                                                            }
                                                            config={editorConfig}
                                                            editorUrl={
                                                                process.env.REACT_APP_BASE_URL +
                                                                "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"
                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>),
                                        },
                                        {
                                            label: `Private Notes`,
                                            key: '2',
                                            children:
                                                (<div
                                                >
                                                    <Form.Item valuePropName='data' name="private_notes"
                                                        getValueFromEvent={(event) => {
                                                            const data = event.editor.getData();
                                                            return data;
                                                        }}>

                                                        <CKEditor
                                                            initData={
                                                                dataSource
                                                                    ? dataSource.private_notes
                                                                    : "<p>Create your Template here!</p>"
                                                            }
                                                            config={editorConfig}
                                                            editorUrl={
                                                                process.env.REACT_APP_BASE_URL +
                                                                "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"
                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>),
                                        },
                                        {
                                            label: `Terms`,
                                            key: '3',
                                            children:
                                                (<div
                                                >
                                                    <Form.Item valuePropName='data' name="terms"
                                                        getValueFromEvent={(event) => {
                                                            const data = event.editor.getData();
                                                            return data;
                                                        }}>
                                                        <CKEditor
                                                            initData={
                                                                dataSource
                                                                    ? dataSource.terms
                                                                    : "<p>Create your Template here!</p>"
                                                            }
                                                            config={editorConfig}
                                                            editorUrl={
                                                                process.env.REACT_APP_BASE_URL +
                                                                "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"
                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>),
                                        },
                                        {
                                            label: `Documents`,
                                            key: '5',
                                            children:
                                                (<div
                                                >
                                                    <Row>
                                                        <Col span={12}>
                                                            <DocumentPicker selectDocuments={handleAddDocument} />
                                                        </Col>
                                                        <Col span={12}>
                                                            <Dragger {...props}>
                                                                <p className="ant-upload-drag-icon">
                                                                    <InboxOutlined />
                                                                </p>
                                                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                                <p className="ant-upload-hint">
                                                                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                                                    band files
                                                                </p>
                                                            </Dragger>
                                                        </Col>
                                                    </Row>
                                                    <Table
                                                        dataSource={documents}
                                                        columns={documentColumns}
                                                        rowClassName="editable-row"
                                                        size="small"
                                                        pagination={false}
                                                    />
                                                </div>),
                                        },
                                        {
                                            label: `Attachments`,
                                            key: '6',
                                            children:
                                                <div>
                                                    <Row>
                                                        <Col span={12}>
                                                            <DocumentPicker selectDocuments={handleAddDocument} />
                                                        </Col>
                                                        <Col span={12}>
                                                            <Dragger {...props}>
                                                                <p className="ant-upload-drag-icon">
                                                                    <InboxOutlined />
                                                                </p>
                                                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                                <p className="ant-upload-hint">
                                                                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                                                    band files
                                                                </p>
                                                            </Dragger>
                                                        </Col>
                                                    </Row>
                                                    <Table
                                                        dataSource={attachments}
                                                        columns={attachmentColumns}
                                                        rowClassName="editable-row"
                                                        size="small"
                                                        pagination={false}
                                                    />
                                                </div>,
                                        },
                                        // {
                                        //     label: `Bank Information`,
                                        //     key: '7',
                                        //     children:
                                        //         (<div
                                        //         >
                                        //             <Form.Item label="Bank Account Number" name='bank_account_number'>
                                        //                 <Input />
                                        //             </Form.Item>
                                        //             <Form.Item label="Bank Name" name='bank_name'>
                                        //                 <Input />
                                        //             </Form.Item>
                                        //             <Form.Item label="IFSC Code" name='bank_ifsc'>
                                        //                 <Input />
                                        //             </Form.Item>
                                        //             <Form.Item label="Bank Branch" name='bank_branch'>
                                        //                 <Input />
                                        //             </Form.Item>
                                        //         </div>),
                                        // },
                                    ]}
                                />
                            </Card>
                        </div>}
                    </Col>
                    <Col span={8} style={{ padding: "0px 0px 10px 10px" }}>
                        <div className="site-card-border-less-wrapper">
                            <Card bordered={false} style={{}}>
                                <Form.Item label="Net">
                                    <span>{net}</span>
                                </Form.Item>
                                <div>
                                    {getTaxes(Object.entries(totalTaxes)).map(item => {
                                        return (
                                            <Form.Item label={item.key}>
                                                <span>{item.value}</span>
                                            </Form.Item>
                                        );
                                    })}
                                </div>
                                {/* <Form.Item label="Sub Total">
                                        <span>{subtotal}</span>
                                    </Form.Item> */}
                                <Form.Item label="Tax" name='tax_rate1'>
                                    <Select
                                        showSearch
                                        placeholder="Select Tax Rate"
                                        optionFilterProp="children"
                                        allowClear
                                        onChange={(value) => {
                                            const taxRate = taxRates.find(item => item.id === value);
                                            console.log(taxRate);
                                            setTaxRate1(taxRate);
                                            // form.setFieldsValue({ "description": product.notes, "price": product.price, "quantity": 1, "line_total": product.price });
                                        }}
                                        onSearch={onClientSearch}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={taxRates.map((item, index) => {
                                            return { value: item.id, label: `${item.title + " " + item.rate} %` }
                                        })}
                                    />
                                </Form.Item>
                                <Form.Item label="Tax" name='tax_rate2'>
                                    <Select
                                        showSearch
                                        placeholder="Select Tax Rate"
                                        optionFilterProp="children"
                                        allowClear
                                        onChange={(value) => {
                                            const taxRate = taxRates.find(item => item.id === value);
                                            console.log(taxRate);
                                            // form.setFieldsValue({ "description": product.notes, "price": product.price, "quantity": 1, "line_total": product.price });
                                            setTaxRate2(taxRate);
                                        }}
                                        onSearch={onClientSearch}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={taxRates.map((item, index) => {
                                            return { value: item.id, label: `${item.title + " " + item.rate} %` }
                                        })}
                                    />
                                </Form.Item>
                                <Form.Item label="Tax" name='tax_rate3'>
                                    <Select
                                        showSearch
                                        placeholder="Select Tax Rate"
                                        optionFilterProp="children"
                                        allowClear
                                        onChange={(value) => {
                                            const taxRate = taxRates.find(item => item.id === value);
                                            console.log(taxRate);
                                            setTaxRate3(taxRate);
                                            // form.setFieldsValue({ "description": product.notes, "price": product.price, "quantity": 1, "line_total": product.price });
                                        }}
                                        onSearch={onClientSearch}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={taxRates.map((item, index) => {
                                            return { value: item.id, label: `${item.title + " " + item.rate} %` }
                                        })}
                                    />
                                </Form.Item>
                                <Form.Item label="Total:">
                                    <span>{unRoundedTotal}</span>
                                </Form.Item>
                                <Form.Item label="Round off:">
                                    <span>{roundValue}</span>
                                </Form.Item>
                                <Form.Item label="Total Amount:">
                                    <span>{total}</span>
                                </Form.Item>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Form>
            }

            {/* Pdf Preview Modal Start */}
            <PdfPreviewModal
                numPages={numPages}
                onDocumentLoadSuccess={events.onDocumentLoadSuccess}
                pdfPreviewData={pdfPreviewData}
                previewPdfVisible={previewPdfVisible}
                setPreviewPdfVisible={setPreviewPdfVisible}
            />
            {/* Pdf Preview Modal End */}

            <Drawer
                title={`${'Send Invoice'}`}
                placement="right"
                size={'large'}
                onClose={() => setOpenSendDrawer(false)}
                open={openSendDrawer}
                style={{ zIndex: 10 }}
                width="50%"
                extra={
                    <Space>
                        <Button onClick={() => setOpenSendDrawer(false)}>Cancel</Button>
                    </Space>
                }
            >
                {openSendDrawer && <SendInvoiceDrawer instance={dataSource} />}
            </Drawer>

            {/* Product Modal Start */}
            <Drawer
                title={`${currentProductInstance ? 'Edit' : 'Add'} Product`}
                placement="right"
                width={"35%"}
                onClose={() => {
                    setCurrentProductInstance(null)
                    setOpenProductForm(false)
                    productForm.resetFields();
                }}
                open={openProductForm}
                style={{ zIndex: 10 }}
            >
                <InvoiceProductForm
                    invoiceId={id}
                    productData={productData}
                    setProductData={setProductData}
                    taxRates={taxRates}
                    instance={currentProductInstance}
                    form={productForm}
                    reload={() => {
                        setCurrentProductInstance(null)
                        setOpenProductForm(false)
                        productForm.resetFields();
                    }}
                />
            </Drawer>
            {/* Product Modal End */}

            {/* Service Modal Start */}
            <Drawer
                title={`${currentServiceInstance ? 'Edit' : 'Add'} Service`}
                placement="right"
                width={"35%"}
                onClose={() => {
                    setCurrentServiceInstance(null)
                    setOpenServiceForm(false)
                    serviceForm.resetFields();
                }}
                open={openServiceForm}
                style={{ zIndex: 10 }}
            >
                <InvoiceServiceForm
                    invoiceId={id}
                    serviceData={serviceData}
                    setServiceData={setServiceData}
                    taxRates={taxRates}
                    instance={currentServiceInstance}
                    form={serviceForm}
                    reload={() => {
                        setCurrentServiceInstance(null)
                        setOpenServiceForm(false)
                        serviceForm.resetFields();
                    }}
                />
            </Drawer>
            {/* Service Modal End */}

        </div >
    );
};
export default InvoiceForm;