import React, { useEffect, useRef, useState } from "react";
import { Button, Popconfirm, Table, Input, Flex, Spin, message } from "antd";
import {
  ImportOutlined,
  UserAddOutlined,
  EditOutlined,
  DeleteOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as actions from "store/actions/AuthActions";
import moment from "moment";
import useScrollBottom from "CustomHooks/useScrollBottom";
import { useSelector } from "react-redux";

const PaymentsTable = () => {
  const organization = useSelector((state) => state.auth.organization);
  const searchRef = useRef();

  // const bottom = useScrollBottom();
  const { Search } = Input;

  const [searchTermLive, setSearchTermLive] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchTerm, setSearchTerm] = useState(searchParams.get("search"));

  // console.log(searchParams.get("search"));

  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const [dataSource, setDataSource] = useState([]);
  const [paymentStatuses, setPaymentStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingProductTrans, setLoadingProductTrans] = useState(false);
  const [loadingTesonTrans, setLoadingTesonTrans] = useState(false);

  const navigate = useNavigate();

  const fetchPayments = async (page, startFresh) => {
    setLoading(true);
    try {
      const response = await actions.loadPayments(page, searchTerm);
      if (response.status === 200) {
        const data = response.data;
        setDataSource([...dataSource, ...data.results]);
        setTotalRows(data.count);
      }
    } catch (e) {}
    setLoading(false);
  };

  const loadPaymentStatuses = async () => {
    const response = await actions.loadPaymentStatuses();
    if (response) {
      setPaymentStatuses(response);
    }
  };

  // useEffect(() => {
  //   // fetchPayments();
  //   loadPaymentStatuses();
  // }, []);

  const onSearch = (value) => {
    if (searchTerm === value) {
    } else {
      setSearchTerm(value);
      setDataSource([]);
      setPageNumber(0);
    }
  };

  useEffect(() => {
    let p = pageNumber + 1;
    // console.log({ next_p: p, dataSource, totalRows });
    if (p === 1) {
      fetchPayments(p);
      setPageNumber(p);
    } else if (dataSource && dataSource.length < totalRows) {
      fetchPayments(p);
      setPageNumber(p);
    }
  }, [searchTerm]);

  // useEffect(() => {
  //   console.log({ pageNumber, searchTerm, dataSource });
  //   fetchPayments(1, true);
  // }, [organization]);

  const handleDelete = async (id) => {
    const newData = dataSource.find((item) => item.id === id);
    // setDataSource(newData);
    const response = await actions.deletePayments(newData.id);
    console.log(response);
    if (response) {
      setSearchTerm("");
      setDataSource([]);
      setPageNumber(0);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const defaultColumns = [
    {
      title: "PAYMENT_ID",
      dataIndex: ["id"],
      key: "id",
      sorter: (a, b) => a?.id?.localeCompare(b?.id),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a?.status.localeCompare(b?.status),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "INVOICE",
      dataIndex: ["invoice_data", "inv_number"],
      key: "inv_number",
      sorter: (a, b) =>
        a?.invoice_data?.inv_number?.localeCompare(b?.invoice_data?.inv_number),
    },
    {
      title: "TRANSACTION_ID",
      dataIndex: ["transaction_id"],
      key: "transaction_id",
      sorter: (a, b) => a?.transaction_id?.localeCompare(b?.transaction_id),
    },
    {
      title: "CLIENT",
      dataIndex: ["invoice_data", "client_data", "name"],
      key: "client_name",
      sorter: (a, b) => a?.client_name.localeCompare(b?.client_name),
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a?.amount - b?.amount,
      align: "right",
    },
    {
      title: "BALANCE",
      dataIndex: "balance",
      key: "balance",
      sorter: (a, b) => a?.balance - b?.balance,
      align: "right",
    },
    {
      title: "DATE",
      dataIndex: "created_at",
      key: "date",
      sorter: (a, b) => moment(a?.date) - moment(b?.date),
    },
    {
      title: "Actions",
      dataIndex: "edit",
      key: "edit",
      render: (_, record) => {
        // console.log(record);
        return dataSource?.length >= 1 ? (
          <Flex gap="middle">
            <Popconfirm
              title="Sure to edit?"
              onConfirm={() => navigate(`/payments/edit/${record.id}/`)}
            >
              <a>
                <EditOutlined
                  style={{
                    verticalAlign: "middle",
                  }}
                />
              </a>
            </Popconfirm>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record.id)}
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </Flex>
        ) : null;
      },
    },
  ];

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const [selectionType, setSelectionType] = useState("checkbox");

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      let p = pageNumber + 1;
      // console.log({ next_p: p, dataSource, totalRows });
      if (p === 1) {
        fetchPayments(p);
        setPageNumber(p);
      } else if (dataSource && dataSource.length < totalRows) {
        fetchPayments(p);
        setPageNumber(p);
      }
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <Flex gap="large" justify="space-between">
        <Flex gap="large" align="center">
          <div>
            ({dataSource?.length} / {totalRows})
          </div>

          <div>
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              style={{ width: 300 }}
              defaultValue={searchTerm}
            />
          </div>
        </Flex>
        <Flex gap="small">
          <Button
            // onClick={handleAdd}
            onClick={async () => {
              const response = await actions.update_payment_statuses();
              if (response.status == 200) {
                console.log(response);
              }
            }}
            icon={<SyncOutlined />}
            type="default"
          >
            Update Payments
          </Button>
          <Button
            // onClick={handleAdd}
            onClick={async () => {
              setLoadingTesonTrans(true);
              const response = await actions.update_payment_statuses();
              if (response.status == 200) {
                console.log(response);
              }
              setLoadingTesonTrans(false);
            }}
            icon={loadingTesonTrans ? <SyncOutlined spin /> : <SyncOutlined />}
            type="default"
          >
            Refresh Teson Transactions
          </Button>
          <Button
            // onClick={handleAdd}
            onClick={async () => {
              setLoadingProductTrans(true);
              const response = await actions.update_payment_statuses();
              if (response.status == 200) {
                console.log(response);
                message.success("Product transactions refreshed successfully!")
              }
              setLoadingProductTrans(false);
            }}
            icon={
              loadingProductTrans ? <SyncOutlined spin /> : <SyncOutlined />
            }
            type="default"
          >
            Refresh Product Transactions
          </Button>
          <Button
            // onClick={handleAdd}
            onClick={() => navigate("/payments/new")}
            icon={<UserAddOutlined />}
            type="primary"
          >
            Add Payment
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <div
          style={{ height: "75vh", overflow: "hidden", marginTop: 20 }}
          onScroll={handleScroll}
        >
          <Table
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            bordered
            size="small"
            dataSource={dataSource}
            columns={columns}
            rowKey={(row) => row.id}
            pagination={false}
            scrollToFirstRowOnChange
            stickyScrollBarBg={"rgba(0, 0, 0, 1)"}
            scroll={{ y: `calc(75vh - 150px)` }}
          />
          <div style={{ margin: 20 }}>
            {loading && (
              <>
                <Spin tip="Loading" size="small">
                  <div className="content" />
                </Spin>
              </>
            )}
          </div>
        </div>
      </Flex>
    </div>
  );
};
export default PaymentsTable;
