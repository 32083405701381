import React, { useState, useEffect } from 'react';
import {
    Button,
    Select,
    Form,
    Input,
    Switch,
    Col,
    Row,
    Card,
    Tabs,
} from 'antd';
import { CKEditor } from "ckeditor4-react";
import * as adminactions from "store/actions/AuthActions";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';


const onChange = (key) => {
    console.log(key);
};

const ClientForm = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState(undefined);
    const [loading, setLoading] = useState(true);

    const editorConfig = {
        extraPlugins: ["tableresize", "uploadimage", "createpdf"],
        removePlugins: [
            "exportpdf",
            "forms",
            "smiley",
            "language",
            "iframe",
            "about",
        ],
        filebrowserImageUploadUrl:
            process.env.REACT_APP_BASE_URL +
            `/template/upload_template_image/`,
        fileTools_requestHeaders: axios.defaults.headers.common,
        uploadUrl:
            process.env.REACT_APP_BASE_URL +
            `/template/upload_template_image/`,
        contentsCss: [
            process.env.REACT_APP_BASE_URL +
            "/staticfiles/ckeditor/4.19.0/full-all/document-style.css",
        ],
        bodyClass: "document-editor",
        height: "5cm",
        width: "19cm",
        allowedContent: true,
        exportPdf_options: {
            header_html: '<div class="styled">Header content</div>',
            footer_html: '<div class="styled"><span class="pageNumber"></span></div>',
            header_and_footer_css:
                ".styled { font-weight: bold; padding: 10px; text-align: center; color: red; }",
            margin_left: "1cm",
            margin_right: "2cm",
            format: "A5",
            page_orientation: "landscape",
        },
        protectedSource: [/{%[\s\S]*?%}+/g, /{{[\s\S]*?}}+/g],
    };

    const fetchClientInfo = async () => {
        if (id) {
            const response = await adminactions.fetchClient(id);
            console.log(response)
            if (response) {
                console.log("Changing state now...")
                let clientContact = {}
                let client_contact = response?.client_contact[0]
                if (client_contact) {
                    clientContact = {
                        "first_name": client_contact["first_name"],
                        "last_name": client_contact["last_name"],
                        "contact_email": client_contact["email"],
                        "contact_phone": client_contact["phone"],
                    }
                }
                setDataSource({ ...response, ...clientContact });
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchClientInfo()
    }, [])

    const onFinish = async (values) => {
        if (values) {
            if (id) {
                const response = await adminactions.updateClients(values, id);
                console.log(response);
                if (response) {
                    navigate("/clients")
                }
            } else {
                const response = await adminactions.addClients(values);
                console.log(response);
                if (response) {
                    navigate("/clients")
                }
            }
        }
    };

    return (
        <div style={{ maxHeight: "90vh", overflowY: "scroll", paddingBottom: 20 }}>
            {!loading &&
                <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinish}
                    initialValues={dataSource}
                >
                    <Row style={{ margin: "15px" }}>
                        {/* FORM_1 */}
                        <Col className="gutter-row" span={12}>
                            <div className="site-card-border-less-wrapper" style={{ paddingBottom: "10px" }} >
                                <Card title="Company Details" bordered={false} >
                                    <Form.Item className="text-start" label="Name" name='name' rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="GST Number" name='gst_number'>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="VAT Number" name='vat_number'>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Mobile" name='phone'>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Email" name='email'>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Currency" name="currency">
                                        <Select >
                                            <Select.Option value='rupees'>Rupees</Select.Option>
                                            <Select.Option value='dollar'>Dollars</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Website" name='website'>
                                        <Input />
                                    </Form.Item>
                                </Card>
                            </div>
                            { /* FORM_2 */}
                            <div className="site-card-border-less-wrapper">
                                <Card title="Contacts" bordered={false} >
                                    <div>
                                        <Form.Item label="First Name" name='first_name'>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Last Name" name='last_name'>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Email" name='contact_email'>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Phone" name='contact_phone'>
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </Card>
                            </div>
                        </Col>
                        { /* FORM_3 */}
                        <Col className="gutter-row" span={12}>
                            <div className="site-card-border-less-wrapper" style={{ padding: "0px 0px 10px 10px" }}>
                                <Card title="Address" bordered={false} >
                                    <Tabs
                                        defaultActiveKey="1"
                                        onChange={onChange}
                                        items={[
                                            {
                                                label: `Billing Address`,
                                                key: '1',
                                                children:
                                                    (<div>
                                                        <Form.Item label="Address line 1" name='billing_address1'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Address line 2" name='billing_address2'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="City" name='billing_city'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="State" name='billing_state' >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Postal Code" name='billing_postal_code' >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Country" name='billing_country'>
                                                            <Input />
                                                        </Form.Item>
                                                    </div>),
                                            },
                                            {
                                                label: `Shipping Address`,
                                                key: '2',
                                                children:
                                                    (<div>
                                                        <Form.Item label="Address line 1" name='shipping_address1'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Address line 2" name='shipping_address2'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="City" name='shipping_city'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="State" name='shipping_state'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Postal Code" name='shipping_postal_code'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Country" name='shipping_country'>
                                                            <Input />
                                                        </Form.Item>
                                                    </div>),
                                            },
                                        ]}
                                    />
                                </Card>
                            </div>
                            <div className="site-card-border-less-wrapper" style={{ padding: "0px 0px 10px 10px" }}>
                                <Card title="Notes" bordered={false} >
                                    <Tabs
                                        defaultActiveKey="1"
                                        onChange={onChange}
                                        items={[
                                            {
                                                label: `Public Notes`,
                                                key: '1',
                                                children:
                                                    <div>
                                                        <Form.Item valuePropName='data' name="public_notes"
                                                            getValueFromEvent={(event) => {
                                                                const data = event.editor.getData();
                                                                return data;
                                                            }}>
                                                            <CKEditor
                                                                initData={dataSource ? dataSource.public_notes : "<p>Create your Template here!</p>"}
                                                                config={editorConfig}
                                                                editorUrl={process.env.REACT_APP_BASE_URL + "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                            },
                                            {
                                                label: `Private Notes`,
                                                key: '2',
                                                children:
                                                    <div>
                                                        <Form.Item valuePropName='data' name="private_notes"
                                                            getValueFromEvent={(event) => {
                                                                const data = event.editor.getData();
                                                                return data;
                                                            }}>
                                                            <CKEditor
                                                                initData={dataSource ? dataSource.private_notes : "<p>Create your Template here!</p>"}
                                                                config={editorConfig}
                                                                editorUrl={process.env.REACT_APP_BASE_URL + "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                            },
                                        ]}
                                    />
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <div style={{ margin: "15px" }}>
                        <Button block type="primary" htmlType="submit" >
                            Save
                        </Button>
                    </div>
                </Form>}
        </div>
    );
};
export default ClientForm;