import axios from "axios";
import { notify } from "components";

// const axios = require("axios").default;
////////////////////////// CATEGORY - Action Creator //////////////////////////

export const categoryLoad = (data) => {
  return {
    type: "LOAD_ALL_CATEGORIES",
    payload: data,
  };
};

////////////////////////// PRODUCT - Action Creator //////////////////////////

export const productLoad = (data) => {
  return {
    type: "LOAD_ALL_PRODUCTS",
    payload: data,
  };
};

export const advertisesLoad = (data) => {
  return {
    type: "LOAD_ALL_ADVERTISES",
    payload: data,
  };
};

export const productSearch = (data) => {
  return {
    type: "SEARCH_PRODUCTS",
    payload: data,
  };
};

export const categoryProductSearch = (data) => {
  return {
    type: "SEARCH_PRODUCTS_OF_CATEGORY",
    payload: data,
  };
};

////////////////////////// MARKETING - Action Creator //////////////////////////

export const sendMessage = (data) => {
  return {
    type: "SEND_MESSAGE",
    payload: data,
  };
};

////////////////////////// REVIEWS - Action Creator //////////////////////////

export const reviewLoad = (data) => {
  return {
    type: "LOAD_ALL_REVIEWS",
    payload: data,
  };
};

export const reviewCreate = (data) => {
  return {
    type: "ADD_REVIEW",
    payload: data,
  };
};

export const reviewRemove = (data) => {
  return {
    type: "REMOVE_REVIEW",
    payload: data,
  };
};

////////////////////////// CATEGORY - Actions //////////////////////////

export const loadCategories = () => {
  return async (dispatch) => {
    try {
      console.log("Loading Categories");
      const response = await axios.get(`/category/`);
      console.log("Response", response);
      dispatch(categoryLoad(response.data));
    } catch (err) {
      console.log("Error", err);
    }
  };
};

////////////////////////// PRODUCT - Actions //////////////////////////

export const loadProducts = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/product/?is_verified=true&is_archived=false`
      );
      console.log("Response", response);
      dispatch(productLoad(response.data));
    } catch (err) {
      console.log("Error", err);
    }
  };
};

export const searchProduct = async (data) => {
  // return async (dispatch) => {
  try {
    const response = await axios.get(
      `/product/?is_verified=true&is_archived=false&search=` + data
    );
    console.log("Response", response);
    // dispatch(productSearch(response.data));
    return response.data;
  } catch (err) {
    console.log("Error", err);
  }
  // };
};

export const searchCategoryProduct = async (
  categoryTitle,
  ordering,
  polygons__gt,
  polygons__lt,
  price__gt,
  price__lt,
  format__icontains
) => {
  // return async () => {

  let paramString = "";
  if (polygons__gt) {
    paramString += `&polygons__gt=${polygons__gt}`;
  }
  if (polygons__lt) {
    paramString += `&polygons__lt=${polygons__lt}`;
  }
  if (ordering) {
    paramString += `&ordering=${ordering}`;
  }
  if (price__lt && price__lt != 1000) {
    paramString += `&price__lt=${price__lt}`;
  }
  if (price__gt) {
    paramString += `&price__gt=${price__gt}`;
  }
  if (format__icontains != undefined) {
    paramString += `&format__icontains=${format__icontains}`;
  }
  try {
    const response = await axios.get(
      `/product/?is_verified=true&is_archived=false&category__title=${categoryTitle}${paramString}`
    );
    console.log("Response", response);
    // dispatch(categoryProductSearch(response.data));
    return response.data;
  } catch (err) {
    console.log("Error", err);
  }
  // };
};

////////////////////////// MARKETING - Actions //////////////////////////

export const createMessage = async (data) => {
  try {
    const response = await axios.post(`/marketing/contact/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("Message could not be sent!", "error");
  }
};

export const loadAdvertises = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/marketing/posters/`);
      console.log("Response", response);
      dispatch(advertisesLoad(response.data));
    } catch (err) {
      console.log("Error", err);
    }
  };
};

////////////////////////// REVIEW - Actions //////////////////////////

export const loadReviews = async (productId, url) => {
  // return async (dispatch) => {
  try {
    console.log("Loading Categories");
    const response = await axios.post(url, {
      productId: productId,
    });
    console.log("Response", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("Error in loading reviews", "error");
  }
};

export const createReview = async (data) => {
  // return async (dispatch) => {
  try {
    const response = await axios.post(`/review/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    // dispatch(reviewCreate(response.data));
    if (response.status === 200) {
      notify("Your review has been recorded!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    alert("Review could not be added!");
  }
  // };
};

export const removeReview = async (reviewID) => {
  // return async (dispatch) => {
  try {
    const response = await axios.delete(`/review/` + reviewID);
    console.log("Response", response);
    return response;
  } catch (err) {
    console.log("Error", err);
    notify("Review could not be deleted!", "error");
  }
  // };
};

////////////////////////// ORDERS - Actions //////////////////////////

export const loadFastMoving = async () => {
  try {
    const response = await axios.get(`/order/fast_moving/`);
    console.log("Response for fast moving:", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
  }
};

////////////////////////// COUNTRY - Actions //////////////////////////

export const loadCountries = async () => {
  try {
    const response = await axios.get(`/user/country/`);
    console.log(response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
  }
};

export const fetchTasks = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post("/utilities/exporttask/my_tasks/");
      if (response.status == 200) {
        dispatch({ type: "UPDATE_TASKS", payload: response.data });
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
};

export const downloadExportFileUsingChunks = async (id) => {
  const chunkSize = 1024*1024*5;
  const fileChunks = [];
  
  const fetchChunk = (id, offset) => {
    return axios.get(`/utilities/download-large-file-by-chunks/${id}/?offset=${offset}`, {
      headers: {
        'Range': `bytes=${offset}-${offset + chunkSize - 1}`,
      },
      responseType: 'blob'
    });
  };

  const downloadFileUsingChunks = async (id) => {
    let offset = 0;
    console.log(id);
    let contentDisposition = '';
    let filename = '';
    while (true) {
      const response = await fetchChunk(id, offset);
      if (response.status === 200) {
        if (response.headers.get('content-length')==0) {
          break;
        }
        const blob = response.data;
        contentDisposition = response.headers['content-disposition']
        filename = contentDisposition.split('filename=')[1];
        fileChunks.push(blob);
        offset += blob.size;

        // If the content-length header is not present, it means we've reached the end of the file
      } else {
        // Handle errors or reach the end of the file
        break;
      }
    }

    const combinedBlob = new Blob(fileChunks);
    console.log(combinedBlob);

    // Create a download link and trigger the download
    const url = window.URL.createObjectURL(combinedBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // Replace with the desired filename
    document.body.appendChild(link);
    link.click();
  }

  try {
    await downloadFileUsingChunks(id);
  } catch (err) {
    console.log("Error: ", err);
  }
}

export const markCompleted = (data) => {
  return async (dispatch) => {
    try {
      // alert();
      const response = await axios.post("/utilities/exporttask/mark_completed/", data);
      if (response.status == 200) {
        dispatch({ type: "UPDATE_TASKS", payload: response.data });
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
};

export const createExportTask = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post("/utilities/exporttask/", data);
      if(response.status == 200) {
        dispatch({ type: "ADD_TASK", payload: response.data });
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  }
}