import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Popconfirm, Table, Flex, Spin, Drawer } from 'antd';
import { ImportOutlined, UserAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import * as actions from "store/actions/AuthActions"
import ServiceForm from './ServiceForm';

const ServicesTable = () => {

  const { Search } = Input;

  const [searchTerm, setSearchTerm] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [currentInstance, setCurrentInstance] = useState(null);
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const fetchServices = async (page) => {
    setLoading(true);
    try {
      let response = await actions.loadServices(page, searchTerm);
      if (response.status == 200) {
        const data = response.data;
        if (page) {
          setDataSource([...dataSource, ...data.results])
          setTotalRows(data.count);
        } else {
          setDataSource([...data.results])
          setTotalRows(data.count);
        }
      }
    } catch (e) {

    }
    setLoading(false);
  }

  const onSearch = (value) => {
    if (searchTerm === value) {

    } else {
      setSearchTerm(value);
      setDataSource([]);
      setPageNumber(0);
    }
  }

  useEffect(() => {
    let p = pageNumber + 1;
    // console.log({ next_p: p, dataSource, totalRows });
    if (p === 1) {
      fetchServices(p);
      setPageNumber(p);
    } else if (dataSource && dataSource.length < totalRows) {
      fetchServices(p);
      setPageNumber(p);
    }
  }, [searchTerm]);

  const handleDelete = async (id) => {
    const newData = dataSource.find((item) => item.id === id);
    // setDataSource(newData);
    const response = await actions.deleteServices(
      newData.id
    );
    console.log(response);
    if (response) {
      fetchServices();
    }
  };


  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  }

  const defaultColumns = [
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a?.title.localeCompare(b?.title),
    },
    {
      title: 'NOTES',
      dataIndex: 'notes',
      key: 'notes',
      sorter: (a, b) => a?.notes.localeCompare(b?.notes),
    },
    {
      title: 'HSN/SAC',
      dataIndex: 'hsn',
      key: 'hsn',
      sorter: (a, b) => a?.hsn.localeCompare(b?.hsn),
    },
    {
      title: 'RATE',
      dataIndex: 'rate',
      key: 'rate',
      sorter: (a, b) => a?.rate - b?.rate,
      // align: 'right'
    },
    {
      title: 'HOURS',
      dataIndex: 'hours',
      key: 'hours',
      sorter: (a, b) => a?.hours - b?.hours,
    },
    {
      title: 'ACTIONS',
      dataIndex: 'Actions',
      key: 'Actions',
      render: (_, record) => {
        // console.log(record);
        return dataSource?.length >= 1 ? (
          <Flex gap="middle">
            <a>
              <EditOutlined
                onClick={() => {
                  setOpenAddDrawer(true)
                  setCurrentInstance(record);
                }}
              />
            </a>
            <Popconfirm title="Sure to delete?"
              onConfirm={() => handleDelete(record.id)}
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </Flex>
        ) : null
      },
    },
  ];


  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const [selectionType, setSelectionType] = useState('checkbox');

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      let p = pageNumber + 1;
      // console.log({ next_p: p, dataSource, totalRows });
      if (p === 1) {
        fetchServices(p);
        setPageNumber(p);
      } else if (dataSource && dataSource.length < totalRows) {
        fetchServices(p);
        setPageNumber(p);
      }
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <Flex gap='large' justify='space-between'>
        <Flex gap='large' align='center'>
          <div>
            ({dataSource?.length} / {totalRows})
          </div>
          <div>
            <Search placeholder="input search text" onSearch={onSearch} style={{ width: 300 }} />
          </div>
        </Flex>
        <Flex gap='large'>
          <Button
            onClick={() => setOpenAddDrawer(true)}
            icon={<UserAddOutlined />}
            type="primary"
          >
            Add Service
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <div style={{ height: "75vh", overflow: "hidden", marginTop: 20 }} onScroll={handleScroll}>
          <Table
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            bordered
            size="small"
            dataSource={dataSource}
            columns={columns}
            rowKey={row => row.id}
            pagination={false}
            scrollToFirstRowOnChange
            stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
            scroll={{ y: `calc(75vh - 150px)` }}
          />
          <div style={{ margin: 20 }}>{
            loading &&
            <>
              <Spin tip="Loading" size="small">
                <div className="content" />
              </Spin>
            </>}
          </div>
        </div>
      </Flex>
      <Drawer
        title={`${currentInstance ? 'Edit' : 'Add'} Service`}
        placement="right"
        width={"35%"}
        onClose={() => {
          setCurrentInstance(null)
          setOpenAddDrawer(false)
          form.resetFields();
        }}
        open={openAddDrawer}
        style={{ zIndex: 10 }}
      >
        <ServiceForm
          instance={currentInstance}
          form={form}
          reload={() => {
            setOpenAddDrawer(false);
            window.location.reload();
          }}
        />
      </Drawer>
    </div>
  );
};
export default ServicesTable;